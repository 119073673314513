import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { DatePicker, Stack } from "rsuite";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Spinner,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import NodataFound from "../../../NodataFound";
import { MultiSelect } from "react-multi-select-component";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

function TimeWiseComparison(props) {
   const [hours, setHours] = useState([]);
  const [todayOrders, setTodayOrders] = useState([]);
  const [yesterdayOrders, setYesterdayOrders] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [allBrand, setAllBrand] = useState(props.allBrand || []);
  const [selectedBrands, setSelectedBrands] = useState([]); 
 
   useEffect(() => {
    setAllBrand(props.allBrand || []);
  }, [props.allBrand]);

   const multiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select Brand",
  };

  let selectedDateMoments = moment(selectedDate);
  let yesterdayDates =  selectedDateMoments
  .clone()
  .subtract(1, "days")
  .format("YYYY-MM-DD");
  let selectedDateFormatteds =  selectedDateMoments.format("YYYY-MM-DD");

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const fetchData = async () => {
    const merchant_id = props.merchantId;
    
    
  
    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_COMPARISION}?merchant_id=${merchant_id}&start_date=${yesterdayDates}&end_date=${selectedDateFormatteds}&brand_id=${selectedBrands}`;
    setLoader(true);
    try {
      const response = await axios.get(url);
      setLoader(false);

      if (response.data.success) {
        const todayData = response?.data?.data?.today || [];
        const yesterdayData = response?.data?.data?.yesterday || [];

        // Check if both todayData and yesterdayData are empty
        if (todayData.length === 0 && yesterdayData.length === 0) {
          setIsDataAvailable(false);
          return;
        }

        setIsDataAvailable(true);

        const hourLabels = todayData.map((value, index) => {
          const hour = value.hour_group;
          const formattedHour = moment(hour, "HH").format("h A");
          return index % 2 === 0 ? formattedHour : "-";
        });
        setHours(hourLabels);

        setTodayOrders(todayData.map((item) => item.total_orders || 0));
        setYesterdayOrders(yesterdayData.map((item) => item.total_orders || 0));
      } else {
        setLoader(false);
        throw new Error(response.data.data.message || "Failed to fetch orders data");
      }
    } catch (error) {
      setLoader(false);
      setIsDataAvailable(false); // No data if error occurs
      Swal.fire("Error", error.message || "Failed to fetch orders data", "error");
    }
  };

  const todayTotalOrderCount = todayOrders.reduce(
    (total, count) => total + count,
    0
  );
  const yesterdayTotalOrderCount = yesterdayOrders.reduce(
    (total, count) => total + count,
    0
  );

  // useEffect(() => {
  //   if (selectedDate) {
  //     fetchData();
  //   }
  // }, [selectedDate]);

  useEffect(()=>{
    fetchData();
  },[])

  const data = {
    labels: hours,
    datasets: [
      {
        label: `Day Before Selected Date (${yesterdayTotalOrderCount})`,
        data: yesterdayOrders,
        borderColor: "#008FFB",
        backgroundColor: "rgba(0, 143, 251, 0.1)",
        tension: 0.4,
        pointRadius: 0, // Remove dots
        pointHoverRadius: 0, // Remove hover dots
        datalabels: {
          display: false, // Hide number labels
        },
      },
      {
        label: `Selected Date (${todayTotalOrderCount})`,
        data: todayOrders,
        borderColor: "#00E396",
        backgroundColor: "rgba(0, 227, 150, 0.1)",
        tension: 0.4,
        pointRadius: 0, // Remove dots
        pointHoverRadius: 0, // Remove hover dots
        datalabels: {
          display: false, // Hide number labels
        },
      },
    ],
  };
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        
        position: "top",
      },
      tooltip: {
        enabled: true,
        intersect: false, // Tooltip appears anywhere near the line
        mode: "nearest", // Tooltip will appear on the nearest point on the line
        callbacks: {
          title: (tooltipItems) => {
            return `Time: ${tooltipItems[0].label}`;
          },
          label: (tooltipItem) => {
            return `Orders: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          color: "#E0E0E0",
          borderColor: "#E0E0E0",
          borderWidth: 2,
          lineWidth: 0.5,
        },
        ticks: {
          maxTicksLimit: 60,
        },
        title: {
          display: true,
          text: `Time [${yesterdayDates} to ${selectedDateFormatteds}]`,
          padding: {
            top: 12, 
          },
        },
      },
      y: {
        grid: {
          display: true,
          color: "#E0E0E0",
          borderColor: "#E0E0E0",
          borderWidth: 2,
          lineWidth: 0.5,
        },
        ticks: {
          stepSize: 10,
          maxTicksLimit: 20,
        },
        title: {
          display: true,
          text: "Orders",
        },
        beginAtZero: true,
      },
    },
  };

  const handleReset = () => {
    const todayDate = new Date();
    setSelectedDate(todayDate);
    setSelectedBrands([]);
  };

  useEffect(() => {
    fetchData();
  }, [selectedDate, selectedBrands]);
  

  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Row>
        <Col>
          <Card className="p-0 my-10 shadow content-wrapper border-0">
            <CardBody>
              <CardTitle tag="h6" style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginBottom:"15px"
                    }}>Orders Comparison</CardTitle>
                <Row lg={6}>
                <Col md={5}>
                <FormGroup>
                  
                    <DatePicker
                      value={selectedDate}
                      format="dd-MM-yyyy"
                      onChange={handleDateChange}
                      disabledDate={(date) => date > new Date()}
                      size="xs"
                      placeholder="Select Date"
                    />

                </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <MultiSelect
                      options={allBrand}
                      value={allBrand.filter((brand) =>
                        selectedBrands.includes(brand.value)
                      )}
                      onChange={(selected) =>
                        setSelectedBrands(selected.map((item) => item.value))
                      }
                      labelledBy="All Brand"
                      overrideStrings={multiSelectSettings}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                                  <button
                                    type="button"
                                    className="btn"
                                    style={{
                                      backgroundColor:"#1b5a8f",
                                      color:"white"
                                    }}
                                    onClick={() => {
                                      fetchData()
                                    }}
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-info ml-1"
                                    outline
                                    onClick={handleReset}
                                  >
                                    Reset
                                  </button>
                                </Col>
              
                  </Row>   
              
              
              <div style={{ height: "350px", width: "100%", position: "relative" }}>
                {loader ? (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Spinner animation="grow" />
                  </div>
                ) : isDataAvailable ? (
                  <Line data={data} options={options} />
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#5e5873",
                      fontSize: "14px",
                    }}
                  >
                    <p className="text-center">
                      <NodataFound />
                    </p>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default TimeWiseComparison;
