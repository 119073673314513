import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
  FormGroup,
  CardHeader,
  Spinner,
  Table,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse
} from "reactstrap";
import { DateRangePicker, DatePicker, Stack } from "rsuite";
import {
  FaFacebook,
  FaGoogle,
  FaInstagram,
  FaYoutube,
  FaEllipsisH,
  FaCity,
  FaShopify,
  FaChevronDown, FaChevronUp
} from "react-icons/fa"; 
import { MultiSelect } from "react-multi-select-component";
import {
  FaCartPlus,
  FaShoppingBag,
  FaChartLine,
  FaCreditCard,
  FaMoneyBillWave,
} from "react-icons/fa";

import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import TimeWiseComparison from "./TimeWiseCompartion";
import { Tooltip } from "reactstrap";
import NodataFound from "../../../NodataFound";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  DoughnutController,
  TooltipOptions,
  Title,
  Legend,
} from "chart.js";
import { Bar, Bubble, Doughnut, Line, Radar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ShoppingBag } from "react-feather";

ChartJS.register(
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartDataLabels,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Legend
);




const AnalyticsDashboard = ({ user }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // Sorting Order
  const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
  const [allBrand, setAllBrand] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  //  console.log(selectedBrands,"sec b")
  const [ordersOverview, setOrdersOverview] = useState({});
  const [averageValue, setAverageValue] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOrdersOpen, setTooltipOrdersOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [CityName, setCityName] = useState([]);
  const [CountCitywise, setCountCitywise] = useState([]);
  const [statename, setStateName] = useState([]);
  const [countSatewise, setCountSatewise] = useState([]);
  const [systemTagsCount, setSystemTagsCount] = useState([]);
  const [systemTagsName, setSystemTagsName] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [socialData, setSocialData] = useState([]);

  const [websitedata, setWebsiteData] = useState([]);
  const [userTagName, setUserTagName] = useState([]);
  const [userTagCount, setUserTagCount] = useState([]);
  const [productData, setProductData] = useState([]);
  const [error, setError] = useState(null);

  const toggleOrdersTooltip = () => setTooltipOrdersOpen(!tooltipOrdersOpen);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const multiSelectSettings = {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select Brand",
  };

  // Brand Data ------------------------------------------------------------------------------------------------------------------------

  const fetchBrands = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_MERCHANT_BRANDS, {
        merchant_id: user[0].id,
      });

      const brands = response.data.data.map((brand) => ({
        value: brand.id,
        label: brand.brand_name,
      }));
      setAllBrand(brands);
    } catch (error) {
      Swal.fire("Error", error.message || "Failed to fetch brands", "error");
    }
  };

  // Total Order Overview-------------------------------------------------------------------------------------------------------------------

  const fetchOrdersData = async (isReset = false) => {
    const merchant_id = user[0].id;

    const start_dateNew = isReset
      ? moment().format("YYYY-MM-DD")
      : moment(filterDate[0]).format("YYYY-MM-DD");
    const end_dateNew = isReset
      ? moment().format("YYYY-MM-DD")
      : moment(filterDate[1]).format("YYYY-MM-DD");

    const brand_id = isReset ? "" : selectedBrands;

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_OVERVIEW}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;
    setLoader(true);
    try {
      const response = await axios.get(url);
      setLoader(false);
      if (response?.data?.data) {
        setOrdersOverview(response.data.data);
      } else {
        setOrdersOverview({});
      }

      if (isReset) {
        setFilterDate([new Date(), new Date()]);
        setSelectedBrands([]);
      }
    } catch (error) {
      setLoader(false);
      Swal.fire(
        "Error",
        error.message || "Failed to fetch orders data",
        "error"
      );
    }
  };

  const calculateAverageOrderValue = () => {
    const totalSales = parseFloat(ordersOverview?.total_sales || 0);
    const totalOrders = parseInt(ordersOverview?.total_orders || 0, 10);

    setAverageValue(totalOrders > 0 ? Math.round(totalSales / totalOrders) : 0);
  };

  useEffect(() => {
    fetchBrands();
    fetchOrdersData();
    getCityStateData();
    getSystemAndUsertagData();
    getSocialNetworkData();
    getWebsitewiseDate();
    fetchProductData();
    getuserTagData();
  }, []);

  useEffect(() => {
    calculateAverageOrderValue();
  }, [ordersOverview]);

  const formatCurrency = (value) => {
    if (value >= 1_00_00_000) {
      return `${(value / 1_00_00_000).toFixed(2)} Cr`;
    } else if (value >= 1_00_000) {
      return `${(value / 1_00_000).toFixed(2)} L`;
    }
    return value.toLocaleString();
  };

  // Social Network Visits Code ------------------------------------------------------------------------------------------------------------

  const getSocialNetworkData = (type) => {
    const merchant_id = user[0].id;

    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;
    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_SOCIAL_VISITS}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;
    setLoader(true);
    axios({
      method: "get",
      url: url,
    })
      .then((res) => {
        setLoader(false);
        const isDataAvailable = res?.data?.data || [];
        if (isDataAvailable.length > 0) {
          setSocialData(isDataAvailable);
        } else {
          setSocialData(null);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching Website and Social data:", err);
      });
  };

  const getWebsitewiseDate = (type) => {
    const merchant_id = user[0].id;

    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;

    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_WEBSITE_NAMES}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;
    setLoader(true);
    axios({
      method: "get",
      url: url,
    })
      .then((res) => {
        setLoader(false);
        const dataAvailabe = res?.data?.data || [];
        if (dataAvailabe) {
          setWebsiteData(dataAvailabe);
        } else {
          setWebsiteData([]);
        }
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching Website:", err);
      });
  };

  // stateandcity code ------------------------------------------------------------------------------------------------------------------

  // const totalOrderCountCity = CountCitywise.reduce((total, count) => total + count, 0);
  // For random color generation

  const LineBarChart = () => {
    // Function to generate random colors for bars
    const generateRandomColor = () => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, 0.7)`;
    };

    const OverallCount = ordersOverview?.total_orders || 0;

    // Calculate percentage for each city
    const percentages = CountCitywise.map(count => ((count / OverallCount) * 100).toFixed(1) + "%");

    const labels = CityName;
    const data = {
        labels,
        datasets: [
            {
                type: "bar",
                label: `Orders`,
                data: CountCitywise,
                backgroundColor: labels.map(() => generateRandomColor()),
                borderColor: "rgba(0, 0, 0, 0.1)",
                borderWidth: 0.5,
            },
        ],
    };

    const options = {
        indexAxis: "y",
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            datalabels: {
                display: true,
                color: "#414a4c",
                font: {
                    size: 11,
                    weight: "bold",
                },
                anchor: "center",
                align: "center",
                formatter: (value, context) => {
                  return `${value} (${percentages[context.dataIndex]})`;
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: false,
                    beginAtZero: true,
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                    font: {
                        size: 12,
                    },
                    beginAtZero: true,
                },
            },
        },
        elements: {
            bar: {
                barThickness: 50,
                borderSkipped: "start",
                borderRadius: 10,
            },
        },
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
};


const LineBarChart2 = () => {
  const generateRandomColor = () => {
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      return `rgba(${r}, ${g}, ${b}, 0.7)`;
  };

  const OverallCount = ordersOverview?.total_orders || 0;

  
  const sortedData = statename.map((name, index) => ({
      name,
      count: Number(countSatewise[index]),
  })).sort((a, b) => b.count - a.count);


  const sortedNames = sortedData.map(item => item.name);
  const sortedCounts = sortedData.map(item => item.count);


  const percentages = sortedCounts.map(count => ((count / OverallCount) * 100).toFixed(1) + "%");

  const data = {
      labels: sortedNames,
      datasets: [
          {
              type: "bar",
              label: `Orders`,
              data: sortedCounts,
              backgroundColor: sortedNames.map(() => generateRandomColor()),
              borderColor: "rgba(0, 0, 0, 0.1)",
              borderWidth: 0.5,
          },
      ],
  };

  const options = {
      indexAxis: "y",
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
          legend: {
              display: false,
          },
          datalabels: {
              display: true,
              color: "#414a4c",
              font: {
                  size: 11,
                  weight: "bold",
              },
              anchor: "center",
              align: "center",
              formatter: (value, context) => {
                return `${value} (${percentages[context.dataIndex]})`; 
              },
          },
      },
      scales: {
          x: {
              grid: {
                  display: false,
              },
              ticks: {
                  display: false,
                  beginAtZero: true,
              },
          },
          y: {
              grid: {
                  display: false,
              },
              ticks: {
                  display: true,
                  font: {
                      size: 12,
                  },
                  beginAtZero: true,
              },
          },
      },
      elements: {
          bar: {
              barThickness: 50,
              borderSkipped: "start",
              borderRadius: 15,
          },
      },
  };

  return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
};



  const getCityStateData = (type) => {
    const merchant_id = user[0].id;

    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;

    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_STATE_CITY}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;
    setLoader(true);
    axios({
      method: "get",
      url: url,
    })
      .then((res) => {
        setLoader(false);
        const getCityData = res?.data?.data?.citywise_results || [];
        const getStateData = res?.data?.data?.statewise_results || [];

        if (getCityData.length > 0) {
          const CityState = getCityData.map(
            (item) => `${item.city}, ${item.state}` || "Unknown"
          );
          const ordersCountDetail = getCityData.map(
            (item) => item.order_count || 0
          );

          setCityName(CityState);
          setCountCitywise(ordersCountDetail);
        } else {
          setCityName([]);
          setCountCitywise([]);
        }

        if (getStateData.length > 0) {
          const state = getStateData.map((item) => item.location || "Unknown");
          const ordersStateCountDetail = getStateData.map(
            (item) => item.order_count || 0
          );

          setStateName(state);
          setCountSatewise(ordersStateCountDetail);
        } else {
          setStateName([]);
          setCountSatewise([]);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error fetching city and state data:", error);
        setStateName([]);
        setCountSatewise([]);
      });
  };

  //   System Tag Code . ---------------------------------------------------------------------------------------------------------

  const getSystemAndUsertagData = (type) => {
    const merchant_id = user[0]?.id;
    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;

    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_TAGS_COUNT}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;

    setLoader(true);

    axios
      .get(url)
      .then((res) => {
        setLoader(false);
        const data = res?.data?.data?.system_tags || [];
          
        if(data.length > 0){
          const tagsName =  data.map((item)=> item.tag)
          const tagCount =  data.map((item)=> item.count)
          setSystemTagsCount(tagCount);
          setSystemTagsName(tagsName);
        }else{
          setSystemTagsCount([]);
          setSystemTagsName([]);
        }
        
      })
      .catch((err) => {
        setLoader(false);
        console.error("Error fetching User and System tags:", err);
      });
  };

  const BarChartSystemTag = () => {
    const uniqueColor = "rgba(75, 192, 192, 0.8)";
    const borderColor = "rgba(75, 192, 192, 0.8)";
  
    const sortedData = systemTagsName.map((name, index) => ({
      name,
      count: Number(systemTagsCount[index]),
    })).sort((a, b) => b.count - a.count);
  
   
    const sortedNames = sortedData.map(item => item.name);
    const sortedCounts = sortedData.map(item => item.count);
  
    
    const totalCount = sortedCounts.reduce((acc, count) => acc + count, 0);
  
    const OverallCount = ordersOverview?.total_orders || 0;
    
    const percentages = OverallCount > 0 ? sortedCounts.map(count => ((count / OverallCount) * 100).toFixed(1) + "%") : 0 ;
  
    const data = {
      labels: sortedNames,
      datasets: [
        {
          label: `System Tags : ${totalCount}`,
          data: sortedCounts,
          backgroundColor: new Array(sortedNames.length).fill(uniqueColor),
          borderColor: new Array(sortedNames.length).fill(borderColor),
          borderWidth: 2,
        },
      ],
    };
  
    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: 'top',
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem) {
              const value = tooltipItem.raw;
              const percentage = ((value / totalCount) * 100).toFixed(1);
              return `Count: ${value} (${percentage}%)`;
            }
          }
        },
        datalabels: {
          color: "#414a4c", 
          anchor: "end", 
          align: "top",
          font: {
            size: 11,
            weight: "bold",
          },
          formatter: (value, context) => {
            return `${value} (${percentages[context.dataIndex]})`;
          },
        },
        afterDatasetsDraw(chart) {
          const { ctx, chartArea } = chart;
          ctx.save();
          ctx.font = 'bold 16px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          const x = chartArea.left + (chartArea.right - chartArea.left) / 2;
          const y = chartArea.top + (chartArea.bottom - chartArea.top) / 2;
          ctx.fillText(`Total Count: ${totalCount}`, x, y);
          ctx.restore();
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: false,
          },
        },
      },
    };
  
    return <Bar data={data} options={options} plugins={[ChartDataLabels]} />;
  };
  
  
 
  // UserTag Tags code ---------------------------------------------------------------------------------------------------------------------



  const BarChartUserTag = () => {
    const colors = [
        "rgba(255, 99, 132, 0.8)",  
        "rgba(255, 159, 64, 0.8)",
        "rgba(255, 205, 86, 0.8)",
        "rgba(75, 192, 192, 0.8)",
        "rgba(54, 162, 235, 0.8)",
        "rgba(153, 102, 255, 0.8)",
        "rgba(201, 203, 207, 0.8)",
        "rgba(255, 140, 180, 0.8)",
        "rgba(0, 206, 209, 0.8)",
        "rgba(255, 215, 0, 0.8)",
        "rgba(34, 139, 34, 0.8)",
        "rgba(72, 61, 139, 0.8)",
    ];

    const borderColors = colors.map(color => color.replace("0.8", "1")); 

   
    const sortedData = userTagName.map((name, index) => ({
        name,
        count: Number(userTagCount[index]),
    })).sort((a, b) => b.count - a.count);

    
    const sortedNames = sortedData.map(item => item.name);
    const sortedCounts = sortedData.map(item => item.count);

    
    const totalCount = sortedCounts.reduce((acc, count) => acc + count, 0);

 
    const OverallCount = ordersOverview?.total_orders || 0;

    
    const percentages = OverallCount > 0 ? sortedCounts.map(count => ((count / OverallCount) * 100).toFixed(1) + "%") : 0 ;

    const data = {
        labels: sortedNames,
        datasets: [
            {
                label: `User Tags : ${totalCount}`,
                data: sortedCounts,
                backgroundColor: sortedNames.map((_, i) => colors[i % colors.length]),
                borderColor: sortedNames.map((_, i) => borderColors[i % borderColors.length]),
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const value = tooltipItem.raw;
                        const percentage = ((value / OverallCount) * 100).toFixed(1);
                        return `Count: ${value} (${percentage}%)`;
                    }
                }
            },
            datalabels: {
                color: "#414a4c",       
                anchor: "end",        
                align: "top",        
                font: {
                    size: 11,          
                    weight: "bolder", 
                },
                formatter: (value, context) => {
                  return `${value} (${percentages[context.dataIndex]})`; 
                },
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
            },
        },
    };

    return <Bar data={data} options={options} plugins={[ChartDataLabels]} />;
};



  
  



  const getuserTagData = (type) => {
    if (!user || user.length === 0) {
      setError("User data is not available.");
      return;
    }

    const merchant_id = user[0].id;
    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;

    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_USER_TAGS_NAMES}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;

    setLoader(true);
    setError(null);

    axios
      .get(url)
      .then((res) => {
        const getusertagsName = res?.data?.data || [];

        if (getusertagsName.length > 0) {
          const usertagname = getusertagsName.map(
            (item) => item.merchant_tags || "Unknown"
          );
          const usertagcount = getusertagsName.map(
            (item) => item.tag_count || 0
          );

          setUserTagName(usertagname);
          setUserTagCount(usertagcount);
        } else {
          setError("No data available.");
          setUserTagName([]);
          setUserTagCount([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching user tag data:", error);
        setError("Failed to fetch data. Please try again later.");
        setUserTagName([]);
        setUserTagCount([]);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  //  const fetchTimeWiseComparison=(okay)=>{
  //     alert(okay)
  //   }

  const resetFuntion = () => {
    fetchOrdersData(true);
    getCityStateData("reset");
    getSystemAndUsertagData("reset");
    getSocialNetworkData("reset");
    getWebsitewiseDate("reset");
    getuserTagData("reset");
    fetchProductData("reset");
    fetchAllStates('reset');
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [dataType, setDataType] = useState(""); // To store 'city' or 'state'

  const toggleModal = (type = "") => {
    setDataType(type);
    setModalOpen(!modalOpen);
  };

  // const dataS2 = userTagName.map((name, index) => ({
  //   merchant_tags: name,
  //   tag_count: userTagCount[index],
  // }));

  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256); // Random Red value between 0 and 255
    const g = Math.floor(Math.random() * 256); // Random Green value between 0 and 255
    const b = Math.floor(Math.random() * 256); // Random Blue value between 0 and 255
    return `rgba(${r}, ${g}, ${b}, 0.7)`; // Return the color as rgba with some transparency
  };

  // Product Wise Data ---------------------------------------------------------------------------------------------------------------------------

  const fetchProductData = (type) => {
    const merchant_id = user[0].id;

    let start_dateNew = filterDate
      ? moment(filterDate[0]).format("YYYY-MM-DD")
      : "";
    let end_dateNew = filterDate
      ? moment(filterDate[1]).format("YYYY-MM-DD")
      : "";
    let brand_id = selectedBrands;

    if (type === "reset") {
      start_dateNew = moment().format("YYYY-MM-DD");
      end_dateNew = moment().format("YYYY-MM-DD");
      setFilterDate([new Date(), new Date()]);
      brand_id = [];
      setSelectedBrands([]);
    }

    const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_PRODUCT_WISE}?merchant_id=${merchant_id}&start_date=${start_dateNew}&end_date=${end_dateNew}&brand_id=${brand_id}`;
    setLoader(true);
    axios({
      method: "get",
      url: url,
    })
      .then((res) => {
        setLoader(false);

        const data = res?.data?.data || [];
        if (data.length > 0) {
          setProductData(data);
        } else {
          setProductData([]);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error fetching Product data:", error);
      });
  };

  const [allStates, setAllStates] = useState([]);
  const [sortedData, setSortedData] = useState(allStates);
  const sortByOrderCount = () => {
    const sorted = [...sortedData].sort((a, b) => {
      return sortOrder === "asc"
        ? a.order_count - b.order_count
        : b.order_count - a.order_count;
    });

    setSortedData(sorted);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


  const fetchAllStates = (type) => {
  
    const merchant_id = user[0].id;
  
    let start_dateNew = filterDate
    ? moment(filterDate[0]).format("YYYY-MM-DD")
    : "";
  let end_dateNew = filterDate
    ? moment(filterDate[1]).format("YYYY-MM-DD")
    : "";
  let brand_id = selectedBrands;

  if (type === "reset") {
    start_dateNew = moment().format("YYYY-MM-DD");
    end_dateNew = moment().format("YYYY-MM-DD");
    setFilterDate([new Date(), new Date()]);
    setSelectedBrands([]);
  }
  
    const url = "https://api.logistify.in/api/v1/admin/statewiseOrder";

    // const url = `${process.env.REACT_APP_MERCHANT_GET_MARKETING_ORDERS_STATE_CITY_OVERVIEW_WISE}`
           
    axios({
      method: "get",
      url: url,
      params: {
        start_date: start_dateNew,
        end_date: end_dateNew,
        merchant_id: merchant_id,
        brand_id: selectedBrands.join(","),
      },
    })
    .then((res) => {
      if (res.data?.data?.statewise_results) {
        setAllStates(res.data?.data?.statewise_results);
        setSortedData(res.data?.data?.statewise_results);
      } else {
        setAllStates([]);
        setSortedData([]);
      }})
      .catch((error) => {
        console.error("Error fetching statewise orders:", error);
        setAllStates([]); // Optionally set an empty array in case of error
      });
  };

  const filteredData = sortedData.filter(
    (item) =>
      item.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.state.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  const [openAccordions, setOpenAccordions] = useState([]);

  const groupedProducts = productData.reduce((acc, product) => {
    if (!acc[product.product_name]) {
      acc[product.product_name] = [];
    }
    acc[product.product_name].push(product);
    return acc;
  }, {});

  // Toggle accordion items
  const toggleAccordion = (productName) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [productName]: !prev[productName],
    }));
  };
  

  return (
    <div>
      {/* {loader && (
        <div className="formLoader">
          <Spinner />
        </div>
      )} */}

      {/* for Overview Count */}
      <Row>
        <Col>
          <Card className="p-0 my-10 shadow content-wrapper border-0">
            <CardHeader className="page-header d-flex flex-wrap justify-content-between">
              <CardTitle className="page-title">Marketing Dashboard</CardTitle>
            </CardHeader>
            <CardBody>
              <Row className="mb-0" lg={4}>
                <Col md={5}>
                  <FormGroup>
                    <DateRangePicker
                      value={filterDate}
                      onChange={setFilterDate}
                      format="dd-MM-yyyy"
                      disabledDate={(date) => date > new Date()}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <MultiSelect
                      options={allBrand}
                      value={allBrand.filter((brand) =>
                        selectedBrands.includes(brand.value)
                      )}
                      onChange={(selected) =>
                        setSelectedBrands(selected.map((item) => item.value))
                      }
                      labelledBy="All Brand"
                      overrideStrings={multiSelectSettings}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <button
                    type="button"
                    className="btn "
                    style={{
                      backgroundColor: "#1b5a8f",
                      color: "white",
                    }}
                    onClick={() => {
                      fetchOrdersData();
                      getCityStateData();
                      getSystemAndUsertagData();
                      getSocialNetworkData();
                      getWebsitewiseDate();
                      getuserTagData();
                      fetchProductData();
                      fetchAllStates();
                    }}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-1"
                    outline
                    onClick={() => {
                      resetFuntion();
                    }}
                  >
                    Reset
                  </button>
                </Col>
              </Row>

              {loader ? (
                <div colSpan="4" className="text-center">
                  <Spinner />
                </div>
              ) : (
                <Row  style={{marginTop:"10px"}}>
                <Col className="py-1">
                  <CardBody className="py-0 d-flex" >
                       <Row className="w-100" >
                        {/* Total Orders */}
                          <Col xs={6} sm={4} md={2} className="d-flex flex-column align-items-center justify-content-center text-center border-right">
                          <CardBody>
                                <div className="d-flex align-items-center mb-2">
                                  <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                  <FaCartPlus
                                    style={{ color: "#666cff" }}

                                    size={24}
                                  />
                                  <h6 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                                    Total Orders
                                  </h6>
                                  </div>
                                </div>
                                <h4 id="totalOrders" style={{textAlign:"center"}}>
                                  {formatCurrency(ordersOverview?.total_orders || 0)}
                                </h4>
        
                                <Tooltip
                                  isOpen={tooltipOrdersOpen}
                                  target="totalOrders"
                                  toggle={toggleOrdersTooltip}
                                >
                                  {ordersOverview?.total_orders?.toLocaleString() ||
                                    "0"}
                                </Tooltip>
                              </CardBody>
                          </Col>
                          {/* Total Sale */}
                          <Col xs={6} sm={4} md={2} className="d-flex flex-column align-items-center justify-content-center text-center border-right">
                          <CardBody>
                                <div className="d-flex align-items-center mb-2">
                                  <FaShoppingBag
                                    style={{ color: "#72e128" }}
                                    className="me-2"
                                    size={24}
                                  />
                                  <h6 className="mb-0" style={{whiteSpace:"nowrap"}}>Total Sales</h6>
                                </div>
                                
                                <h4 id="totalSales" style={{whiteSpace:"nowrap", textAlign:"center"}}>
                                  ₹ {formatCurrency(ordersOverview?.total_sales || 0)}
                                </h4>
                                <Tooltip
                                  isOpen={tooltipOpen}
                                  target="totalSales"
                                  toggle={toggleTooltip}
                                >
                                  {
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "-8px",
                                        }}
                                      >
                                        Total Sale - ₹
                                        {ordersOverview?.total_sales?.toLocaleString() ||
                                          "0"}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "-8px",
                                        }}
                                      >
                                        Total Prepaid - ₹
                                        {ordersOverview?.TotalPrepaid?.toLocaleString() ||
                                          "0"}
                                      </p>
                                      <p style={{ fontSize: "14px" }}>
                                        Total COD - ₹
                                        {ordersOverview?.TotalCOD?.toLocaleString() ||
                                          "0"}
                                      </p>
                                    </div>
                                  }
                                </Tooltip>
                              </CardBody>
                          </Col>
                          {/* Total COD */}
                          <Col  xs={6} sm={4} md={2} className="d-flex flex-column align-items-center justify-content-center text-center border-right">
                          <CardBody>
                                <div className="d-flex align-items-center mb-2">
                                  <FaMoneyBillWave
                                    style={{ color: "#007bff" }}
                                    className="me-2"
                                    size={24}
                                  />
                                  <h6 className="mb-0">Total COD</h6>
                                </div>
                                <h4 id="totalCOD" style={{whiteSpace:"nowrap", textAlign:"center"}}>
                                  ₹ {formatCurrency(ordersOverview?.TotalCOD || 0)}
                                </h4>
                              </CardBody>
                          </Col>
                          {/* Total Prepaid */}
                          <Col xs={6} sm={4} md={3} className="d-flex flex-column align-items-center justify-content-center text-center border-right">
                           <CardBody className="w-100">
                          <div className="d-flex align-items-center justify-content-center mb-2">
                            <FaCreditCard
                              style={{ color: "#f39c12" }}
                              className="me-2"
                              size={24}
                            />
                            <h6 className="mb-0" style={{ whiteSpace: "nowrap" }}>Total Prepaid</h6>
                          </div>
                          <h4 id="totalPrepaid" style={{ whiteSpace: "nowrap" }}>
                            ₹ {formatCurrency(ordersOverview?.TotalPrepaid || 0)}
                          </h4>
                        </CardBody>
                      </Col>
                          {/* Average Value */}
                          <Col  xs={6} sm={4} md={3} className="d-flex flex-column align-items-center justify-content-center text-center">
                          <CardBody>
                                <div className="d-flex align-items-center mb-2">
                                  <FaChartLine
                                    style={{ color: "#26c6f9" }}
                                    className="me-2"
                                    size={24}
                                  />
                                  <h6 className="me-2" >Average Order Value</h6>
                                </div>
                                <h4 style={{whiteSpace:"nowrap", textAlign:"center"}}>₹ {averageValue}</h4>
                              </CardBody>
                          </Col>
                       </Row>
                    </CardBody>  
               
               </Col>
               </Row>
              )} 
            </CardBody>
          </Card>
        </Col>
      </Row>
       {/* New Overview Changes */}
       {/* <Row  style={{marginTop:"10px"}}>
        <Col>
       <Card className="shadow border-0 py-2 ">
          <CardBody className="py-0 d-flex" >
               <Row className="w-100" >
                {/* Total Orders */}
                  {/* <Col xs={6} sm={4} md={2} className="border-right">
                  <CardBody>
                        <div className="d-flex align-items-center mb-2">
                          <FaCartPlus
                            style={{ color: "#666cff" }}
                            className="me-1"
                            size={24}
                          />
                          <h6 className="mb-0" style={{ whiteSpace: "nowrap" }}>
                            Total Orders
                          </h6>
                        </div>
                        <h4 id="totalOrders">
                          {formatCurrency(ordersOverview?.total_orders || 0)}
                        </h4>

                        <Tooltip
                          isOpen={tooltipOrdersOpen}
                          target="totalOrders"
                          toggle={toggleOrdersTooltip}
                        >
                          {ordersOverview?.total_orders?.toLocaleString() ||
                            "0"}
                        </Tooltip>
                      </CardBody>
                  </Col>
                  {/* Total Sale */}
                  {/* <Col xs={6} sm={4} md={2} className="border-right">
                  <CardBody>
                        <div className="d-flex align-items-center mb-2">
                          <FaShoppingBag
                            style={{ color: "#72e128" }}
                            className="me-2"
                            size={24}
                          />
                          <h6 className="mb-0">Total Sales</h6>
                        </div>
                        <h4 id="totalSales">
                          ₹ {formatCurrency(ordersOverview?.total_sales || 0)}
                        </h4>
                        <Tooltip
                          isOpen={tooltipOpen}
                          target="totalSales"
                          toggle={toggleTooltip}
                        >
                          {
                            <div>
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "-8px",
                                }}
                              >
                                Total Sale - ₹
                                {ordersOverview?.total_sales?.toLocaleString() ||
                                  "0"}
                              </p>
                              <p
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "-8px",
                                }}
                              >
                                Total Prepaid - ₹
                                {ordersOverview?.TotalPrepaid?.toLocaleString() ||
                                  "0"}
                              </p>
                              <p style={{ fontSize: "14px" }}>
                                Total COD - ₹
                                {ordersOverview?.TotalCOD?.toLocaleString() ||
                                  "0"}
                              </p>
                            </div>
                          }
                        </Tooltip>
                      </CardBody>
                  </Col> */} 
                  {/* Total COD */}
                  {/* <Col  xs={6} sm={4} md={2} className="border-right">
                  <CardBody>
                        <div className="d-flex align-items-center mb-2">
                          <FaMoneyBillWave
                            style={{ color: "#007bff" }}
                            className="me-2"
                            size={24}
                          />
                          <h6 className="mb-0">Total COD</h6>
                        </div>
                        <h4 id="totalCOD">
                          ₹ {formatCurrency(ordersOverview?.TotalCOD || 0)}
                        </h4>
                      </CardBody>
                  </Col> */}
                  {/* Total Prepaid */}
                  {/* <Col  xs={6} sm={4} md={3} className="border-right">
                  <CardBody>
                        <div className="d-flex align-items-center mb-2">
                          <FaCreditCard
                            style={{ color: "#f39c12" }}
                            className="me-2"
                            size={24}
                          />
                          <h6 className="mb-0">Total Prepaid</h6>
                        </div>
                        <h4 id="totalPrepaid">
                          ₹ {formatCurrency(ordersOverview?.TotalPrepaid || 0)}
                        </h4>
                      </CardBody>
                  </Col> */}
                  {/* Average Value */}
                  {/* <Col  xs={6} sm={4} md={3}>
                  <CardBody>
                        <div className="d-flex align-items-center mb-2">
                          <FaChartLine
                            style={{ color: "#26c6f9" }}
                            className="me-2"
                            size={24}
                          />
                          <h6 className="me-2">Average Order Value</h6>
                        </div>
                        <h4>₹ {averageValue}</h4>
                      </CardBody>
                  </Col>
               </Row>
            </CardBody>  
       </Card>
       </Col>
       </Row> */}

      {/* TimeCompartion Wise-------------------------------------------------------------------------------------------------- */}
      {/* <>
        <TimeWiseComparison
          date={filterDate}
          merchantId={user[0].id}
          brandId={selectedBrands}
          allBrand={allBrand}
          selectedBrand={selectedBrands}
        />
      </> */}
      {/* Website wise and Social media wise---------------------------------------------------------------------------------------------- */}
      <Row>
        <Col>
          <CardBody>
            <Row md="12">
              {/* Left Table */}
              <Col>
                <CardTitle
                  tag="h6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Website-Wise Data Breakdown
                </CardTitle>
                <div
                  className="table-scroll-sm shadow-sm border-1"
                  style={{
                    overflowX: "auto",
                    overflowY: "auto",
                    borderRadius: "5px",
                    maxHeight: "550px",
                  }}
                >
                  <table
                    bordered
                    hover
                    style={{
                      textAlign: "center",
                      width: "100%",
                      whiteSpace: "nowrap",
                      border: "none",
                      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      overflow: "hidden",
                      backgroundColor: "white",
                    }}
                  >
                    <thead
                      style={{
                        backgroundColor: "#fff",
                        color: "#3b4056",
                        fontSize: "14px",
                        fontWeight: "600",
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                      }}
                    >
                      <tr>
                        <th style={{ padding: "12px 16px" }}>Website</th>
                        <th style={{ padding: "12px 16px" }}>Total Count</th>
                        <th style={{ padding: "12px 16px" }}>COD</th>
                        <th style={{ padding: "12px 16px" }}>Prepaid</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        color: "#5E5873",
                        fontSize: "13px",
                      }}
                    >
                      {loader ? (
                        <tr>
                          <td colSpan="4" className="text-center">
                            <Spinner />
                          </td>
                        </tr>
                      ) : websitedata.length <= 0 ? (
                        <tr>
                          <td colSpan="4" className="text-center">
                            <NodataFound />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {/* Total Row */}
                          <tr
                            style={{
                              background: "#8080802b",
                              fontWeight: "bold",
                              borderBottom: "1px solid #eee",
                              fontSize:"14px"
                            }}
                          >
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              {websitedata.reduce(
                                (acc, curr) => acc + curr.total,
                                0
                              )}
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>
                                    {websitedata.reduce(
                                      (acc, curr) =>
                                        acc + (parseInt(curr.cod, 10) || 0),
                                      0
                                    )}
                                  </span>
                                  <span style={{ margin: "0 5px" }}>|</span>
                                  <span>
                                    ₹{" "}
                                    {formatCurrency(
                                      websitedata.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr.cod_total_amount || 0
                                          ),
                                        0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>
                                    {websitedata.reduce(
                                      (acc, curr) =>
                                        acc + (parseInt(curr.prepaid, 10) || 0),
                                      0
                                    )}
                                  </span>
                                  <span style={{ margin: "0 5px" }}>|</span>
                                  <span>
                                    ₹{" "}
                                    {formatCurrency(
                                      websitedata.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr.prepaid_total_amount || 0
                                          ),
                                        0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          {/* Data Rows */}
                          {websitedata
                            .sort((a, b) => b.total - a.total)
                            .map((data, index) => {
                              let getTotalOrders = data?.total || 0;
                              let getCodOrders = data.cod || 0;
                              let getPrepaidOrders = data.prepaid || 0;

                              let calculateCodPercentage =
                                getTotalOrders > 0
                                  ? (getCodOrders / getTotalOrders) * 100
                                  : 0;
                              let calculatePrepaidPercentage =
                                getPrepaidOrders > 0
                                  ? (getPrepaidOrders / getTotalOrders) * 100
                                  : 0;

                               let totalOrders =  websitedata.reduce(
                                (acc, curr) => acc + curr.total,
                                0
                              )
                              
                              let calculateCountPercentage = totalOrders >0 ? (getTotalOrders/totalOrders)*100 : 0 ;

                              return (
                                <tr
                                  key={index}
                                  style={{
                                    background: "#FFFFFF",
                                    borderBottom: "1px solid #eee",
                                    fontSize: "13.5px",
                                  }}
                                >
                                  <td
                                    style={{
                                      padding: "5px 5px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src={
                                          data.logo && data.logo !== "NA"
                                            ? process.env
                                                .REACT_APP_IMAGE_MERCHANT +
                                              data.logo
                                            : "https://photosly.net/wp-content/uploads/2023/12/no-dp32.jpg"
                                        } 
                                        style={{
                                          height: "37px",
                                          width: "37px",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                          marginRight: "10px",
                                        }}
                                        alt="Brand logo"
                                      />
                                      <span
                                        style={{
                                          fontWeight: "600",
                                          color: "#3b4056",
                                          width: "100px",
                                        }}
                                      >
                                        {data.brand_name || "Unknown"}
                                      </span>
                                    </div>
                                  </td>                                
                                  <td style={{ padding: "5px 5px" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                        }}
                                      >
                                        {data.total || 0}
                                       
                                      </div>

                                      <span
                                        style={{
                                          backgroundColor: "#FFEFB3",
                                          color: "#E79A3A",
                                          marginTop: "5px",
                                          padding: "3px 3px",
                                          borderRadius: "12px",
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          display: "inline-block",
                                        }}
                                      >
                                        {calculateCountPercentage.toFixed(2)}%
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ padding: "5px 5px" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <span>{formatCurrency(data.cod) || 0}</span>
                                        <span style={{ margin: "0 5px" }}>
                                          |
                                        </span>
                                        <span>
                                          ₹{" "}
                                          {formatCurrency(
                                            Math.round(data.cod_total_amount) ||
                                              0
                                          )}
                                        </span>
                                      </div>

                                      <span
                                        style={{
                                          backgroundColor: "#F0F8FF",
                                          color: "#6CB4EE",
                                          marginTop: "5px",
                                          padding: "3px 3px",
                                          borderRadius: "12px",
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          display: "inline-block",
                                        }}
                                      >
                                        {calculateCodPercentage.toFixed(2)}%
                                      </span>
                                    </div>
                                  </td>
                                  <td style={{ padding: "5px 5px"}}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        fontWeight: "500",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "center",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <span>{formatCurrency(data.prepaid) || 0}</span>
                                        <span style={{ margin: "0 5px" }}>
                                          |
                                        </span>
                                        <span>
                                          ₹{" "}
                                          {formatCurrency(
                                            Math.round(data.prepaid_total_amount)
                                          ) || 0}
                                        </span>
                                      </div>
                                      <span
                                        style={{
                                          backgroundColor: "#e8fadd",
                                          color: "#72e128",
                                          padding: "3px 3px",
                                          borderRadius: "12px",
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                          display: "inline-block",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {calculatePrepaidPercentage.toFixed(2)}%
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </Col>
              <>
        <TimeWiseComparison
          date={filterDate}
          merchantId={user[0].id}
          brandId={selectedBrands}
          allBrand={allBrand}
          selectedBrand={selectedBrands}
        />
      </>

              {/* Right Table */}
              <Col md="12" style={{marginTop:"10px"}}>
                <CardTitle
                  tag="h6"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                >
                  Social Network Visits
                </CardTitle>
                <div
                  className="shadow-sm border-1"
                  style={{
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    className="table-scroll-sm"
                    style={{
                      overflowX: "auto",
                      overflowY: "auto",
                      maxHeight: "550px",
                    }}
                  >
                    <table
                      bordered
                      hover
                      style={{
                        textAlign: "center",
                        width: "100%",
                        whiteSpace: "nowrap",
                        border: "none",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <thead
                        style={{
                          backgroundColor: "#fff", //#f5c6bf
                          color: "#3b4056",
                          fontSize: "14px",
                          fontWeight: "600",

                          whiteSpace: "nowrap",
                         
                        }}
                      >
                        <tr>
                          <th style={{ padding: "12px 16px" }}>
                            Social Media Platforms
                          </th>
                          <th style={{ padding: "12px 16px" }}>Total Count</th>
                          <th style={{ padding: "12px 16px" }}>COD</th>
                          <th style={{ padding: "12px 16px" }}>Prepaid</th>
                        </tr>
                      </thead>
                      {loader ? (
                        <div colSpan="4" className="text-center">
                          <Spinner />
                        </div>
                      ) : socialData ? (
                        <>
                        {/* Total Row */}
                        <tr
                            style={{
                              background: "#8080802b",
                              fontWeight: "bold",
                              borderBottom: "1px solid #eee",
                              fontSize:"14px"
                            }}
                          >
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              Total
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              {socialData.reduce(
                                (acc, curr) => acc + curr.order_count,
                                0
                              )}
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>
                                    {socialData.reduce(
                                      (acc, curr) =>
                                        acc + (parseInt(curr.cod_count, 10) || 0),
                                      0
                                    )}
                                  </span>
                                  <span style={{ margin: "0 5px" }}>|</span>
                                  <span>
                                    ₹{" "}
                                    {formatCurrency(
                                      socialData.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr.cod_total_amount || 0
                                          ),
                                        0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                padding: "10px 10px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  <span>
                                    {socialData.reduce(
                                      (acc, curr) =>
                                        acc + (parseInt(curr.prepaid_count, 10) || 0),
                                      0
                                    )}
                                  </span>
                                  <span style={{ margin: "0 5px" }}>|</span>
                                  <span>
                                    ₹{" "}
                                    {formatCurrency(
                                      socialData.reduce(
                                        (acc, curr) =>
                                          acc +
                                          Math.round(
                                            curr.prepaid_total_amount || 0
                                          ),
                                        0
                                      )
                                    )}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                       { socialData
                          .sort((a, b) => b.order_count - a.order_count)
                          .map((data, index) => {
                            let getTotalOrders = data?.order_count || 0;
                            let getCodOrders = data.cod_count || 0;
                            let getprepaidOrders = data.prepaid_count || 0;

                            let calulateCodpercentage =
                              getTotalOrders > 0
                                ? (getCodOrders / getTotalOrders) * 100
                                : 0;
                            let calulateprepaidpercentage =
                              getprepaidOrders > 0
                                ? (getprepaidOrders / getTotalOrders) * 100
                                : 0;
                            
                            let total_Count = socialData?.reduce(
                              (acc, curr) => acc + curr.order_count,
                              0
                            )    

                            let CalculateCountpercentage =    total_Count > 0 ? (getTotalOrders /total_Count ) *100 : 0;

                            return (
                              <tr
                                key={index}
                                style={{
                                  background: "#FFFFFF",
                                  borderBottom: "1px solid #eee",
                                  fontSize: "13.5px",
                                }}
                              >
                                <td
                                  style={{
                                    padding: "5px 5px",
                                    textAlign: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {data.referral_source === "Facebook" ? (
                                      <FaFacebook
                                        style={{
                                          fontSize: "28px",
                                          color: "#3b5998",
                                          marginRight: "10px",
                                        }}
                                      />
                                    ) : data.referral_source === "Google" ? (
                                      <FaGoogle
                                        style={{
                                          fontSize: "28px",
                                          color: "#db4a39",
                                          marginRight: "10px",
                                        }}
                                      />
                                    ) : data.referral_source === "Instagram" ? (
                                      <FaInstagram
                                        style={{
                                          fontSize: "28px",
                                          color: "#c13584",
                                          marginRight: "10px",
                                        }}
                                      />
                                    ) : data.referral_source === "Shopify" ? (
                                      <FaShopify
                                        style={{
                                          fontSize: "28px",
                                          color: "#c13584",
                                          marginRight: "10px",
                                        }}
                                      />
                                    )
                                     : data.referral_source === "YouTube" ? (
                                      <FaYoutube
                                        style={{
                                          fontSize: "28px",
                                          color: "#ff0000",
                                          marginRight: "10px",
                                        }}
                                      />
                                    ) : (
                                      <FaEllipsisH
                                        style={{
                                          fontSize: "28px",
                                          color: "#999999",
                                          marginRight: "10px",
                                        }}
                                      />
                                    )}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        color: "#3b4056",
                                        width: "100px",
                                      }}
                                    >
                                      {data.referral_source || "Unknown"}
                                    </span>
                                  </div>
                                </td>
                                <td style={{ padding: "5px 5px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <div
                                    
                                    >
                                      <span>{data.order_count || 0}</span>
                                     
                                    </div>
                                    <span
                                      style={{
                                        backgroundColor: "#FFEFB3",
                                        color: "#E79A3A",
                                        marginTop: "5px",
                                        padding: "3px 3px",
                                        borderRadius: "12px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        display: "inline-block",
                                      }}
                                    >
                                      {CalculateCountpercentage.toFixed(2)}%
                                    </span>
                                  </div>
                                </td>
                                <td style={{ padding: "5px 5px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <span>{data.cod_count || 0}</span>
                                      <span style={{ margin: "0 5px" }}>|</span>
                                      <span>
                                        ₹{" "}
                                        {formatCurrency(
                                          Math.round(data.cod_total_amount) || 0
                                        )}
                                      </span>
                                    </div>

                                    <span
                                      style={{
                                        backgroundColor: "#F0F8FF",
                                        color: "#6CB4EE",
                                        marginTop: "5px",
                                        padding: "3px 3px",
                                        borderRadius: "12px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        display: "inline-block",
                                      }}
                                    >
                                      {calulateCodpercentage.toFixed(2)}%
                                    </span>
                                  </div>
                                </td>
                                <td style={{ padding: "5px 5px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                      fontWeight: "500",
                                    }}
                                  >
                                    <div
                                      style={{
                                       ordsplay: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        marginRight: "10px",
                                      }}
                                    >
                                      <span>{data.prepaid_count || 0}</span>
                                      <span style={{ margin: "0 5px" }}>|</span>
                                      <span>
                                        ₹{" "}
                                        {formatCurrency(
                                          Math.round(data.prepaid_total_amount)
                                        ) || 0}
                                      </span>
                                    </div>
                                    <span
                                      style={{
                                        backgroundColor: "#e8fadd",
                                        color: "#72e128",
                                        padding: "3px 3px",
                                        borderRadius: "12px",
                                        fontSize: "10px",
                                        fontWeight: "bold",
                                        display: "inline-block",
                                        marginTop: "5px",
                                      }}
                                    >
                                      {calulateprepaidpercentage.toFixed(2)}%
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                         </>       
                        ) : (
                        <tr>
                          <td colSpan="3" className="text-center">
                            <NodataFound />
                          </td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
     
      {/* Product Wise */}
      <Row>
  <Col style={{ marginTop: "10px" }}>
    <CardBody>
      <Row md="12">
        {/* Left Table */}
        <Col>
          <CardTitle
            tag="h6"
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            Top 10 Performing Products
          </CardTitle>
          <div
            className="table-scroll-sm shadow-sm border-1"
            style={{
              overflowX: "auto",
              overflowY: "auto",
              borderRadius: "5px",
              maxHeight: "540px",
              backgroundColor:"white",
              fontSize: "14px",
            }}
          >
               <div
      className="table-responsive shadow-sm border-1 p-3"
      style={{ borderRadius: "5px", maxHeight: "540px", overflowY: "auto" }}
    >
      {loader ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : Object.keys(groupedProducts).length === 0 ? (
        <div className="text-center"><NodataFound /></div>
      ) : (
        Object.entries(groupedProducts).map(([productName, items], index) => {
          const brandName = items[0].brand_name; // Extract brand name from first item
          const totalCount = items.reduce((sum, item) => sum + item.product_count, 0);
    
          return (
            <div key={index} className="mb-2">
  <div
    onClick={() => toggleAccordion(productName)}
    className="d-flex justify-content-between align-items-center p-2"
    style={{
      backgroundColor: "#fff",
      borderRadius: "5px",
      cursor: "pointer",
      border: "1px solid #ddd",
      fontWeight: "500",
      transition: "0.3s",
    }}
    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e9ecef")}
    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#fff")}
  >
    <span>
      <span className="mr-1">{productName}</span> |
      <span className="ml-1 mr-1">(Count- {totalCount})</span> |
      <span className="ml-1 mr-1">{brandName}</span>
    </span>
    {openAccordions[productName] ? <FaChevronUp /> : <FaChevronDown />}
  </div>
  <Collapse isOpen={openAccordions[productName]}>
    <div
      className="p-3"
      style={{
        backgroundColor: "#f5f6f6", // Light blue background  
        borderRadius: "5px",
        marginTop: "5px",
      }}
    >
      {items.map((item, subIndex) => (
        <div key={subIndex} className="my-2" style={{display:"flex", gap:"15px", borderBottom:"1px solid rgb(238, 238, 238)"}}>
          <span>
         <span className="ml-1 mr-1">{item.product_name} ({item.size})</span>|
         <span className="ml-1 mr-1">{item.product_invoice_value}</span>
         </span>
         <span style={{whiteSpace:"nowrap"}}>(Count-{item.product_count})</span>          
        </div>
        
      ))}
    </div>
  </Collapse>
</div>

          );
        })
      )}
    </div>
          </div>
        </Col>
      </Row>
    </CardBody>
  </Col>
</Row>

      {/* CityWise and StateWise------------------------------------------------------------------------------------------------------------ */}
      <Row >
        <Col>
          <CardBody>
            <Row>
              <Col md={6} style={{marginTop:"10px"}}>
                <Card className="my-10 shadow content-wrapper border-0">
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardTitle
                      tag="h6"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Top 10 City
                    </CardTitle>
                    <Button
                      color="light" // Light background color
                      style={{
                        borderRadius: "30px", // Rounded corners
                        padding: "8px 20px", // Adjusting padding
                        display: "flex", // Aligning icon and text in a row
                        alignItems: "center", // Centering the icon and text vertically
                      }}
                      onClick={() =>{
                        toggleModal("city");
                        fetchAllStates();
                      } }
                    >
                      <FaCity
                        style={{ marginRight: "8px", fontSize: "18px" }}
                      />{" "}
                      {/* City Icon */}
                      View City & State Detail
                    </Button>
                  </CardHeader>
                  <CardBody>
                    {loader ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : CityName.length <= 0 && CountCitywise.length <= 0 ? (
                      <p className="text-center">
                        <NodataFound />
                      </p>
                    ) : (
                      <div style={{ height: "280px", width: "100%" }}>
                        <LineBarChart />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>

              <Col md={6} style={{marginTop:"10px"}}>
                <Card className="my-10 shadow content-wrapper border-0">
                  <CardHeader
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      
                    }}
                  >
                    <CardTitle
                      tag="h6"
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        marginTop: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {" "}
                      Top 10 State
                    </CardTitle>
                  </CardHeader>

                  <CardBody>
                    {loader ? (
                      <div className="text-center">
                        <Spinner />
                      </div>
                    ) : statename.length <= 0 && countSatewise.length <= 0 ? (
                      <p className="text-center">
                        <NodataFound />
                      </p>
                    ) : (
                      <div style={{ height: "280px", width: "100%" }}>
                        <LineBarChart2 />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>

      {/* Modal for detailed chart */}
      <Modal style={{ maxWidth: "100%", margin: 0, height: "100%" }} isOpen={modalOpen} toggle={() => toggleModal("")}>
      <ModalHeader toggle={() => toggleModal("")}></ModalHeader>
      <ModalBody style={{ height: "100%" }}>
        {allStates.length > 0 ? (
          <>
            <h5>City Wise Data</h5>
            <div
              className="shadow-sm border-1"
              style={{
                backgroundColor: "#FFFFFF",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                padding: "10px",
                width: "100%",
                height: "100vh",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  maxHeight: "calc(100vh - 100px)",
                  overflowY: "auto",
                  borderRadius: "8px",
                }}
              >
                <table
                  className="table table-bordered"
                  style={{
                    textAlign: "center",
                    width: "100%",
                    whiteSpace: "nowrap",
                    borderRadius: "8px",
                    overflow: "hidden",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <thead
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#e7e7ff",
                      color: "#3b4056",
                      fontSize: "14px",
                      fontWeight: "600",
                      borderBottom: "2px solid #ddd",
                      zIndex: 1000,
                    }}
                  >
                    <tr style={{ position: "sticky", zIndex: 1000 }}>
                      <th style={{ padding: "12px 16px" , width:'5%' }}>State</th>
                      <th style={{ padding: "12px 16px",width:'5%'  }}>City</th>
                      <th style={{ padding: "12px 16px",width:'5%'  }}>Order Count</th>
                      <th style={{ padding: "12px 16px" , width:'5%'  }}>COD Count</th>
                      <th style={{ padding: "12px 16px" , width:'5%'  }}>Prepaid Count</th>
                      <th style={{ padding: "12px 16px" ,width:'5%'  }}>COD Total Amount</th>
                      <th style={{ padding: "12px 16px" , width:'5%'  }}>Prepaid Total Amount</th>
                      <th style={{ padding: "12px 16px", width: "5%" }}>Percentage (%)</th> 
                    </tr>
                    <tr>
                      <td colSpan="8">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by city or state..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          style={{
                            width: "15%",
                            padding: "8px",
                            borderRadius: "4px",
                            border: "1px solid #ddd",
                          }}
                        />
                      </td>
                    </tr>
                  </thead>
                  <tbody>
  {filteredData.length > 0 ? (
    filteredData.map((item, index) => {
      const totalOrders = filteredData.reduce((sum, item) => sum + item.order_count, 0);
      const percentage = totalOrders ? ((item.order_count / totalOrders) * 100).toFixed(2) : 0; 

      return (
        <tr key={index} style={{ background: "#FFFFFF", borderBottom: "1px solid #eee", fontSize: "13.5px" }}>
          <td style={{ padding: "10px", fontWeight: "500" }}>{item.state}</td>
          <td style={{ padding: "10px", fontWeight: "500" }}>{item.city}</td>
          <td style={{ padding: "10px", fontWeight: "600" }}>{item.order_count}</td>
          <td style={{ padding: "10px", color: "#6CB4EE" }}>{item.cod_count}</td>
          <td style={{ padding: "10px", color: "#72e128" }}>{item.prepaid_count}</td>
          <td style={{ padding: "10px", color: "#FF5733" }}>₹ {item.cod_total_amount}</td>
          <td style={{ padding: "10px", color: "#28A745" }}>₹ {item.prepaid_total_amount}</td>
          <td style={{ padding: "10px", fontWeight: "600", color: "#FF8C00" }}>{percentage} %</td> 
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="8" className="text-center" style={{ padding: "20px" }}>
        No Data Available
      </td>
    </tr>
  )}
</tbody>

                  {/* FOOTER: TOTAL COUNT & AMOUNT */}
                  <tfoot
                    style={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#f1f1f1",
                      fontWeight: "bold",
                      borderTop: "2px solid #ddd",
                      zIndex: 1000,
                    }}
                  >
                    <tr>
                      <td colSpan="2" style={{ padding: "12px 16px", textAlign: "center" }}>
                        Total
                      </td>
                      <td style={{ padding: "12px 16px" }}>
                        {filteredData.reduce((sum, item) => sum + item.order_count, 0)}
                      </td>
                      <td style={{ padding: "12px 16px", color: "#6CB4EE" }}>
  {(() => {
    const total = filteredData.reduce(
      (sum, item) => sum + (Number(item.cod_count) || 0), 
      0
    );

    if (isNaN(total)) return "0"; // Fallback to "0" if total is NaN

    return total >= 100000
      ? (total / 100000).toFixed(2) + " Lakh" // Convert to Lakh with 2 decimal places
      : total >= 1000
      ? (total / 1000).toFixed(2) + "K" // Convert to Thousand with 2 decimal places
      : total.toFixed(2); // Ensure 2 decimal places for small values
  })()}
</td>


<td style={{ padding: "12px 16px", color: "#72e128" }}>
  {(() => {
    const total = filteredData.reduce(
      (sum, item) => sum + (Number(item.prepaid_count) || 0), 
      0
    );

    if (isNaN(total)) return "0"; // Fallback to "0" if total is NaN

    return total >= 100000
      ? (total / 100000).toFixed(2) + " Lakh" // Convert to Lakh with 2 decimal places
      : total >= 1000
      ? (total / 1000).toFixed(2) + "K" // Convert to Thousand with 2 decimal places
      : total.toFixed(2); // Ensure 2 decimal places for small values
  })()}
</td>


<td style={{ padding: "12px 16px", color: "#FF5733" }}>
  ₹ {(() => {
    const total = filteredData.reduce(
      (sum, item) => sum + (Number(item.cod_total_amount) || 0),
      0
    );

    if (isNaN(total)) return "0"; // Fallback to "0" if total is NaN

    return total >= 10000000
      ? (total / 10000000).toFixed(2) + " Cr" // Convert to Crore with 2 decimal places
      : total >= 100000
      ? (total / 100000).toFixed(2) + " Lakh" // Convert to Lakh with 2 decimal places
      : total >= 1000
      ? (total / 1000).toFixed(2) + "K" // Convert to Thousand with 2 decimal places
      : total.toFixed(2); // Ensure at least 2 decimal places for small values
  })()}
</td>


<td style={{ padding: "12px 16px", color: "#28A745" }}>
  ₹ {(() => {
    const total = filteredData.reduce(
      (sum, item) => sum + (Number(item.prepaid_total_amount) || 0), 
      0
    );

    if (isNaN(total)) return "0"; // Fallback in case total is not a number

    return total >= 10000000
      ? (total / 10000000).toFixed(3) + " Cr"
      : total >= 100000
      ? (total / 100000).toFixed(3) + " Lakh"
      : total >= 1000
      ? (total / 1000).toFixed(3) + "K"
      : total.toFixed(3);
  })()}
</td>


                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </>
        ) : (
          <p>No data available</p>
        )}
      </ModalBody>
    </Modal>


      {/* UserTag and System Tag ---------------------------------------------------------------------------------------------------------*/}
      <Row>
        <Col>
          <CardBody>
            <Row>
              <Col md={6} style={{marginTop:"5px"}}>
                <CardBody
                  className="my-10  shadow content-wrapper border-0"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "auto",
                    // minHeight:"380px",
                  }}
                >
                  <CardTitle
                    tag="h6"
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginTop: "5px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    Top 10 User Tags
                  </CardTitle>
                  {loader ? (
                    <div className="text-center" style={{ marginTop: "100px" }}>
                      <Spinner />
                    </div>
                  ) : error ? (
                    <p className="text-center">
                      <NodataFound />
                    </p>
                  ) : userTagName.length > 0 && userTagCount.length > 0 ? (
                    <div
                      style={{
                        padding: "10px",
                      }}
                    >
                       <BarChartUserTag />                      
                    </div>
                  ) : (
                    <p style={{textAlign:"center"}}>No user tag data available to display.</p>
                  )}
                </CardBody>
              </Col>
              <Col md={6} style={{marginTop:"5px"}}>
                <CardBody
                  className="shadow content-wrapper border-0"
                  style={{
                    width: "auto",
                    // height: "380px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                  }}
                >
                  <CardTitle
                    tag="h6"
                    style={{
                      fontSize: "15px",
                      fontWeight: "bold",
                      marginTop: "5px",
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    System Tags
                  </CardTitle>

                  {/* Show Loader */}
                  {loader ? (
                    <div className="text-center" style={{ marginTop: "100px" }}>
                      <Spinner />
                    </div>
                  ) : systemTagsName?.length > 0 ? (
                    <>
                    <div style={{padding:"10px"}}>
                      <BarChartSystemTag/>
                      </div>
                    </>
                  ) : (
                    // No Data Found Component
                    <p className="text-center">
                      <NodataFound />
                    </p>
                  )}
                </CardBody>
              </Col>
            </Row>
          </CardBody>
        </Col>
      </Row>
    </div>
  );
};

export default AnalyticsDashboard;
