import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, Spinner, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip, Alert } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faTimes, faChevronDown, faPlus, faChevronRight, faDownload, faCartPlus, faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import axios from 'axios';
import { changeIntoDate, firstDayOftheMonth, copyToClipBoard, moreText, errorHandling, remExtraSpace } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import { baseString, delivery_type_id, download_delay } from '../../config';
import EditBulkOrders from './EditBulkOrders';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { toast } from 'react-toastify';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { connect } from 'react-redux';
import moment from 'moment';

const UploadOrders = (props) => {

  const [deliveryType, setDeliveryType] = useState([]);
  const [excelFile, setExcelFile] = useState();
  const [excelmodal, setExcelmodal] = useState(false);
  const [allOrder, setAllOrder] = useState({});
  const [loadertable, setLoadertable] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [cancelmodal, setCancelmodal] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [searchValue, setSearchValue] = useState(' ');
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxOrderId, setCheckboxOrderId] = useState([]);
  const [checked, setChecked] = useState(false);
  const [merchantId, setMerchantId] = useState('');
  const [overallchecked, setOverallChecked] = useState(false);
  const [changeCourierModal, setChangeCourierModal] = useState(false);
  const [courierList, setCourierList] = useState({});



  const dropFilterCount = 20
  const history = useHistory();

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  const changeCourierToggle = () => {
    if (checkboxOrderId.length > 0) {
      setChangeCourierModal(!changeCourierModal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const { register: changeCourier, handleSubmit: changeCourierSubmit, formState: { errors: errors5 }, reset: resetChangeCourier } = useForm();
  const onChangeCourierSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    // axios.post(`${process.env.REACT_APP_CHANGE_COURIER}`, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CHANGE_COURIER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.fatal !== true && res.data.error !== true) {
        Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success' }).then(okay => {
          if (okay) {
            // bulkOrderListing();
            onCommonFilter();
            setChangeCourierModal(false);
            resetChangeCourier();
          }
        });
      }
      else {
        Swal.fire({ title: 'Error', text: res.data.msg, icon: 'error', })
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  }
  const selectAll = (value) => {
    setOverallChecked(false);

    let abc = checkData(allOrder.data) && allOrder.data.map((key) => (
      key === false
    ));
    if (value) {
      // checkData(allOrder.data)&&allOrder.data.map((item) => (
      // setCheckboxOrderId(item)
      var opt_order_id = [];
      checkData(allOrder.data) && Object.keys(allOrder.data).map(function (key) {
        let tempObj = allOrder.data[key].id
        opt_order_id.push(tempObj)
        return setCheckboxOrderId(opt_order_id)
      });
      // ));
    } else {
      setCheckboxOrderId([])
    }

    setChecked(abc);
    setCheckedAll(value);
    setChecked((prevState) => {
      const newState = { ...prevState };
      for (const utilityCheckbox in newState) {
        newState[utilityCheckbox] = value;
      }
      return newState;
    });
  };

  const selectOverAll = () => {
    setOverallChecked(true);
    // setCheckboxOrderId([]);
  }

  const toggleCheck = (utilityCheckbox, event, Id) => {
    setOverallChecked(false);
    var opt_order_id = checkboxOrderId;

    setChecked((prevState) => {
      const newState = { ...prevState };
      newState[utilityCheckbox] = !prevState[utilityCheckbox];
      return newState;
    });
    if (event.target.checked) {
      opt_order_id.push(Id)
      setCheckboxOrderId(opt_order_id)
    } else {
      function arrayRemove(arr, value) {
        return arr.filter(function (ele) {
          return ele !== value;
        });
      }
      var result = arrayRemove(checkboxOrderId, Id);
      setCheckboxOrderId(result)
    };
  }


  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const toggleEditClose = () => setEditmodal(false);
  const toggleEdit = (productId, merchantId) => {
    setMerchantId(merchantId)
    let data = {}
    data = { "order_id": productId, "merchant_id": merchantId, "is_edit": 1 }
    setEditmodal(!editmodal);
    // axios.post(process.env.REACT_APP_ORDER_DETAILS, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_ORDER_DETAILS,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setOrderDetail(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "");
    })
  }

  const excelUpload = () => {
    setExcelmodal(!excelmodal);
    orderReset();
    if (!excelmodal) { setExcelFile(""); }
  }


  const cancelmodalToggle = () => {
    if (checkboxOrderId.length > 0) {
      setCancelmodal(!cancelmodal);
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }

  const handleFileInput = (e) => {
    setExcelFile(e.target.files[0])
  }
  useEffect(() => {
    setLoadertable(true)
    bulkOrderListing();
    couurierListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //listing bulk Order
  const bulkOrderListing = () => {
    setFilterDate([firstDayOftheMonth(), new Date()]);
    let data = { "order_status_id": 1, "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    reset(); setDeliveryType([]); setSearchValue(' ');
    setOverallChecked(false);
    setCheckboxOrderId([]);
    setCheckedAll(false);
    setChecked(false);

    // axios.post(process.env.REACT_APP_ALL_ORDER, data)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setAllOrder(res.data);
        }
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    })
  }

  const { register: uploadOrderdata, handleSubmit: orderUploadSubmit, formState: { errors: errors3 }, reset: orderReset } = useForm();

  const onUploadOrderSubmit = (data) => {
    
    let formData = new FormData();
    formData.append("file", excelFile);
    if(props.user[0].user_type === "Merchant"){
      formData.append("merchant_id", checkData(props.user) ? props.user[0].id : '');
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
    }else{
      formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
      formData.append("adminOnly", "1");

    }
    
    setLoader(true);
    axios({
      method: "post",
      url: process.env.REACT_APP_UPLOAD_ORDER,
      data: formData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      let errorFile = '';
      if (res.data.errorfile !== undefined) {
        errorFile = checkUndeNullBlank(res.data.errorfile) ? process.env.REACT_APP_BASE_URL + res.data.errorfile : '';
      }
      setExcelFile(); orderReset();
      setExcelmodal(false);
      if (res.data.error === false) {
        Swal.fire({
          title: 'Success',
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td>${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' ? `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>` : ''}</tbody></Table>`,
          text: res.data.msg,
          icon: 'success',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            bulkOrderListing();
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: res.data.msg,
          html: `<Table style="width:100%;" className="table w-100 text-left alertTable"><tbody><tr><th colspan="2" style="padding: 0.25rem 0;">${res.data.msg}</th></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Total Uploaded</th><td style="padding: 0.25rem 0;">${res.data.torder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Successfull</th><td style="padding: 0.25rem 0;">${res.data.sorder}</td></tr><tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed</th><td style="padding: 0.25rem 0;">${res.data.forder}</td></tr>${errorFile !== '' && `<tr style="border-bottom: 1px solid #ebe9f1;"><th style="padding: 0.25rem 0;">Failed File</th><td style="padding: 0.25rem 0;"><a href=${errorFile} >Download File</a></td></tr>`}</tbody></Table>`,
          icon: 'error',
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(okay => {
          if (okay) {
            bulkOrderListing();
          }
        });
      }
    })
      .catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      });
  };
  const placeBulkOrder = () => {
    let data = { "order_ids": checkboxOrderId }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
      data.merchant_wallet_balance = props.user[0].wallet_balance
    }
    if (checkboxOrderId.length > 0) {
      setLoader(true);
      // axios.post(process.env.REACT_APP_PLACE_BULK_ORDER, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_PLACE_BULK_ORDER,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        setLoader(false);
        if (res.data.error !== true && res.data.fatal !== true) {
          Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', allowOutsideClick: false, allowEscapeKey: false }).then(okay => {
            if (okay) {
              history.push("/orders?o=processing");
            }
          });
        } else {
          Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
        }
      }).catch((err) => {
        setLoader(false);
        errorHandling(err, "");
      })
    } else {
      Swal.fire({ icon: 'warning', title: 'Warning', text: 'Please Select The Order First' })
    }
  }
  const exportShipment = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = props.user[0].id
      } else {
        data.merchant_id = props.user[0].id
      }
      data.order_status_id = 1
      data.is_export = 1
      setLoader(true);
      // axios.post(process.env.REACT_APP_ALL_ORDER, data)
      axios({
        method: "post",
        url: process.env.REACT_APP_ALL_ORDER,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false);
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
            // setAllShipment(res.data);
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(allOrder.data) && checkUndeNullBlank(allOrder.totalPage)) {
      // &&checkUndeNullBlank(allOrder.totalPage).length>page.length
      if (allOrder.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allOrder.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(allOrder.data)
      if (currentCount > Object.entries(allOrder.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  const { register: filter, handleSubmit: filterOrderSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterOrder = (data, pagePerpage) => {
    pagePerpage.page = 1;
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(data.delivery_type_id) || checkData(filterDate)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };

  const callOnFilterOrder = () => {
    onFilterOrder(filterInput, { "page": "" });
  }

  const couurierListing = () => {
    if (checkData(props.user) && props.user[0].user_type === "Merchant") {
      setLoader(true)
      axios({
        method: "post",
        url: process.env.REACT_APP_MERCHANT_COURIER_LIST,
        data: { "merchant_id": checkData(props.user) && props.user[0].id },
        headers: { "token": window.btoa(baseString) }
      }).then(function (response) {
        setLoader(false)
        if (response.data.error === false) {
          setCourierList(response.data.data.courierList)
        } else {
          Swal.fire({ title: 'Error', text: response.data.msg ? response.data.msg : 'Something went wrong', icon: 'error' });
        }

      }).catch(function (err) {
        setLoader(false);
        errorHandling(err, "reload")
      })
    }
  }

  const onCommonFilter = (data, pagePerpage) => {
    setOverallChecked(false);
    setCheckedAll(false);
    setChecked(false);
    setCheckboxOrderId([]);
    let byData = {}
    let tmpDeliveryType = []


    deliveryType.map((item) => (
      tmpDeliveryType.push(item.value)
    ));

    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    byData = { "order_status_id": 1, "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_ORDER}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllOrder(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };


  //cancel Order
  const { register: cancelOrder, handleSubmit: cancelOrderSubmit, formState: { errors: errors4 }, reset: resetCancelled } = useForm();
  const onCancelOrderSubmit = data => {
    setLoader(true);
    data.order_ids = checkboxOrderId
    // axios.post(process.env.REACT_APP_CANCEL_ORDER, data)
    axios({
      method: "post",
      url: process.env.REACT_APP_CANCEL_ORDER,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data.sqlMessage !== undefined) {
        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
      }
      if (res.data.error === false) {
        Swal.fire({ icon: 'success', title: 'Success', text: res.data.msg })
        setCancelmodal(false);
        resetCancelled();
        setCheckboxOrderId([]);
        // bulkOrderListing();
        onCommonFilter();
        setCheckedAll(false);
        setChecked(false);
      } else {
        Swal.fire({ icon: 'error', title: 'Error', text: res.data.msg })
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "");
    });
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    // <div className="app-content content overflow-hidden">
    <div>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 manageOrder shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Orders</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {props.user[0].user_type === "Merchant" &&
                    <>
                      <div className="btn-text btn-light" onClick={changeCourierToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowsAltH} /></span><span>Change Courier</span></div>
                      <Link to='/create-order' className="btn-text btn-light mr-2"><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Single Order</span></Link>
                      <div className="btn-text btn-light mr-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Orders</span></div>
                      {checkData(allOrder.data) && checkUndeNullBlank(props.user[0].ship_status) && parseInt(props.user[0].ship_status) === 1 &&
                        <div className="btn-text btn-light mr-2" onClick={placeBulkOrder}><span className="icon mr-2"><FontAwesomeIcon icon={faCartPlus} /></span><span>Ship Orders</span></div>
                      }
                    </>
                  }
                  {props.user[0].user_type === "Super" && <div className="btn-text btn-light mr-2" onClick={excelUpload}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowUp} /></span><span>Upload Orders</span></div>}

                  {checkData(allOrder.data) &&
                    <>
                      <div className="btn-text btn-light mr-2" onClick={cancelmodalToggle}><span className="icon mr-2"><FontAwesomeIcon icon={faTimes} /></span><span>Cancel Orders</span></div>
                      <div className="btn-text btn-light " onClick={exportShipment}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download</span></div>
                    </>
                  }
                </div>
                <Modal isOpen={changeCourierModal} toggle={changeCourierModal} >
                  <ModalHeader toggle={changeCourierToggle}>Change Courier</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={changeCourierSubmit(onChangeCourierSubmit)}>
                      <Row className="m-0 pb-1">
                        <Col sm={12}>
                          <FormGroup className='position-relative'>
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...changeCourier("courier_id", { required: 'Please Choose Courier' })} className="form-control-sm form-control border-right-0">
                              <option value="">Select Courier</option>
                              {/* {checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers) && props.state.shipmentMerchantCourier.couriers.map((item) => (
                                <option value={item.courier_id}>{item.courierName}</option>
                              ))} */}
                               {checkData(courierList) && courierList.map((item) => (
                              item.status !== 0 &&
                              <option value={item.courier_id}>{item.courierName}</option>
                            ))}
                            </select>
                            {errors5.courier_id && <span className="text-danger d-block error">{errors5.courier_id.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
                <Modal isOpen={excelmodal} toggle={excelmodal} scrollable={true} >
                  <ModalHeader toggle={excelUpload}>Upload Bulk Orders</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={orderUploadSubmit(onUploadOrderSubmit)}>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Upload all Your Orders via XLSX</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Please download below given template</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Remove sample data and upload your data</p>
                      <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span>Make sure you atleast fill mandatory fields.</p>

                      <Row className="m-0 pb-1">
                        <Col sm={12} className="p-0">
                          <p className="mb-2"><span className="mr-2"><FontAwesomeIcon icon={faChevronRight} /></span> <Link target="_blank" download to="/format/order.xlsx" className=""><FontAwesomeIcon icon={faDownload} />  Download
                          </Link>
                            <span className="ml-2">Order Format File</span></p>
                        </Col>
                        <Col sm={12} className="m-auto pt-1 p-0">
                          <FormGroup>
                            <input {...uploadOrderdata("excelUpload", {
                              required: 'Please Upload XLSX File',
                              validate: {
                                lessThan5MB: files => files[0]?.size < 5242880 || 'Max 5MB',
                                acceptedFormats: files =>
                                  ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(
                                    files[0]?.type
                                  ) || 'Only .xslx file allowed',
                              },
                            })} type="file" id="excelUpload" accept=".xlsx" className="custom-file-input" onChange={handleFileInput} />
                            <Label className="custom-file-label" for="inputGroupFile01">{excelFile !== '' && excelFile !== undefined ? excelFile.name : 'Choose file'}</Label>
                            {errors3.excelUpload && <span className="text-danger d-block error">{errors3.excelUpload.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Upload</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>

                <Modal isOpen={cancelmodal} toggle={cancelmodal} scrollable={true} >
                  <ModalHeader toggle={cancelmodalToggle}>Cancel Orders</ModalHeader>
                  <ModalBody>
                    <Form onSubmit={cancelOrderSubmit(onCancelOrderSubmit)}>
                      <Row className="m-0 pb-1">
                        <Col sm={12}>
                          <FormGroup>
                            <Label for="remaks">Remarks</Label>
                            <textarea {...cancelOrder("remarks", { required: 'Please Enter Remaks' })} rows="4" className="form-control-sm form-control" placeholder="Remarks" id="remarks" />
                            {errors4.remarks && <span className="text-danger d-block error">{errors4.remarks.message}</span>}
                          </FormGroup>
                        </Col>
                        <Col sm={12} className="text-right">
                          <Button className="btn ctm-btn btn-sm" color="primary">Submit</Button>
                        </Col>
                      </Row>
                    </Form>
                  </ModalBody>
                </Modal>
              </CardHeader>
              {checkData(orderDetail) &&
                <Modal isOpen={editmodal} toggle={editmodal} scrollable={true} size="xl">
                  <ModalHeader toggle={toggleEditClose}>Edit Order</ModalHeader>
                  <ModalBody>
                    <EditBulkOrders orderDetails={orderDetail} merchantId={merchantId} setEditmodal={setEditmodal} user={props.user} bulkOrderListing={bulkOrderListing} callOnFilterOrder={callOnFilterOrder} />
                  </ModalBody>
                </Modal>
              }
              <Form onSubmit={filterOrderSubmit(onFilterOrder)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-lg-center">
                  <Col sm={6} md={5} lg={5} xl={4}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control-sm form-control rounded-right-0" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } }}>
                              <option value="">Search By</option>
                              <option value="customer_name">Customer Name</option>
                              <option value="customer_name">Customer Pincode</option>
                              <option value="customer_mobile">Customer Mobile</option>
                              <option value="order_no">Order No</option>
                              <option value="product_name">Product Name</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={3} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Delivery Type</option>
                        {Object.entries(delivery_type_id).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" placement="bottomEnd" ref={dateRangeRef} change={onDateChange} />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={bulkOrderListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(allOrder.data).length)} of {allOrder.TotalRows}</span> : ''}
                </div>
                {checkboxOrderId.length > 0 && !overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkboxOrderId.length} items on this page are selected.</span>{checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows > selectperpage && checkedAll && <span onClick={selectOverAll} className='cursor-pointer text-primary d-none'>Select all <span className='font-weight-bold'>{checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows}</span> items across all pages</span>}
                  </Alert>
                }
                {overallchecked &&
                  <Alert color="secondary" className='small total-select' fade={false}>
                    <span className='mx-1'>Total {checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) && allOrder.TotalRows} items are Selected.</span>
                  </Alert>
                }
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th><div className="form-check2"><input name="cust-type" type="checkbox" id="select_all" checked={checkedAll} onChange={(event) => selectAll(event.target.checked)} value="checkedall" /><label className="form-check-label" for="select_all"></label></div></th>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant Name</th>
                      }
                      <th className="text-nowrap">Pickup Point </th>
                      <th className="text-nowrap">Order No</th>
                      <th className="text-nowrap">Delivery</th>
                      <th className="text-nowrap">Customer Details </th>
                      <th className="col-2 text-nowrap">Product</th>
                      <th className="text-nowrap">Uploaded</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(allOrder.data) ? allOrder.data.map((item, index) => (
                      <tr>
                        <td><div className="form-check2"><input checked={checked !== undefined && checked[index]} name={'check' + index} type="checkbox" id={item.id} onChange={(event) => toggleCheck(index, event, item.id)} disabled={overallchecked} /><label className="form-check-label" for={item.id}></label></div></td>
                        {props.user[0].user_type === "Super" &&
                          <td>{item.merchantName}<br />{item.merchantBrandName}</td>
                        }
                        <td>{item.MerchantPickupPointName}</td>
                        <td><div className="text-nowrap"><Link target="_blank" to={`/order-detail/${item.id}`} from={"a"}>{item.order_no}</Link>
                          <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div></td>
                        <td>{delivery_type_id[item.delivery_type_id]}</td>
                        <td>{item.customer_name}<br />{item.customer_mobile}</td>
                        <td><div id={`MoreProduct${item.id}`}>{moreText(item.product_name)}</div>
                          <UncontrolledTooltip placement="top" target={`MoreProduct${item.id}`}>
                            {item.product_name}
                          </UncontrolledTooltip>
                        </td>
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')} <span><Button className="mx-2 btn-sm" color="primary" outline onClick={() => toggleEdit(item.id, item.merchant_id)}>Edit</Button></span></td>
                      </tr>
                    )) :
                      loadertable ? <tr>
                        <td colSpan="8"><LoaderDefault /></td>
                      </tr>
                        : <tr><td colSpan="8" className="text-center"><NodataFound /></td></tr>
                    }
                  </tbody>
                </Table>

                {checkData(allOrder) && checkData(allOrder.data) && checkUndeNullBlank(allOrder.TotalRows) ?
                  <>
                    {allOrder.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(UploadOrders);
