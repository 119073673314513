import React, { useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Table, Form, Row, Col, Button, Spinner } from 'reactstrap';
import { DateRangePicker } from 'rsuite';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../../../ObjectExist';
import { changeIntoDateYmd, errorHandling } from '../../../../utlis';
import LoaderDefault from '../../../LoaderDefault';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faHistory } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { baseString, download_delay } from '../../../../config';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux'
import { dashUploadSummary } from '../../../../actions';
import Marquee from "react-fast-marquee";

const OrderSummary = (props) => {
    const [filterDate, setFilterDate] = useState([new Date(), new Date()]);
    const [uploadedSummary, setUploadedSummary] = useState({});
    const [loadertable, setLoadertable] = useState(false);
    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();

    // const [summaryType, setSummaryType] = useState('today');
    // const summaryChange = (e) => {
    //     setSummaryType(e.target.value)
    //     setUploadedSummary({});
    //     let type = e.target.value
    //     listingSummary(type)
    // }
    const listingSummary = (type) => {
        setLoadertable(true);
        setUploadedSummary({});
        // let data = {"type":checkUndeNullBlank(type) ?type: summaryType }
        let mypost = { "merchant_id": props.user[0].id, "start_date": checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "end_date": checkData(filterDate) && filterDate.length > 0 ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
        let myget = {}
        dispatch(dashUploadSummary(mypost, myget)).then((response) => {
            if (response.error === false) {
                setUploadedSummary(response.data);
            }
            setLoadertable(false)
        }).catch(function (response) {
            setLoadertable(false);
            errorHandling(response, "reload");
        });
    }
    useEffect(() => {
        if (checkData(props.state) && checkData(props.state.viewOrderSummary) && checkData(props.state.viewOrderSummary.data)) {
            setUploadedSummary(props.state.viewOrderSummary.data);
        } else {
            listingSummary();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  


    let totalcourier_id = [];

    const exportShipment = (courierid, total) => {
        let courierId = [];
        courierid.forEach(item => {
            courierId.push(item)
        });

        let data = { "all_shipment": 1, "start_date": checkData(filterDate) ? changeIntoDateYmd(filterDate[0]) : '', "end_date": checkData(filterDate) ? changeIntoDateYmd(filterDate[1]) : '' }

        data.courier_id = courierId;
        data.total = checkUndeNullBlank(total) ? total : "";

        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }
        data.is_export = 1
        setLoader(true);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_ALL_SHIPMENTS}`,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
                if (parseInt(res.data.errno) > 0) {
                    setLoader(false);
                    if (res.data.sqlMessage !== undefined) {
                        Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                    }
                } else {
                    if (res.data.is_export === 1) {
                        setTimeout(function () {
                            setLoader(false);
                            history.push("/my-downloads");
                        }, download_delay);
                    }
                }
            }
        }).catch((err) => {
            errorHandling(err, "")
        });

    }
    let totalCod = 0;
    let totalPrepaid = 0;
    let totalCodPrepaid = 0;

    let uploadedSummaryData = checkData(uploadedSummary) && Object.values(uploadedSummary.reduce((osi, item) => {
        let courierId = item.courier_id
        if (!osi[courierId])
            osi[courierId] = { courier_id: courierId, courier_name: item.courierName, logo: item.logo };

        let codcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 1 ? item.total : 0;
        let prepaidcount = checkUndeNullBlank(item.delivery_type_id) && parseInt(item.delivery_type_id) === 3 ? item.total : 0;
        if (codcount > 0) {
            osi[courierId].cod = codcount;
        }
        if (prepaidcount > 0) {
            osi[courierId].prepaid = prepaidcount;
        }
        // osi[courierId].prepaid = prepaidcount;

        totalCod += codcount;
        totalPrepaid += prepaidcount;
        totalCodPrepaid = totalCod + totalPrepaid
        return osi;
    }, {}));


    return (
        <div className="orderSummary w-100 d-flex">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Card className="shadow border-0 w-100">
            <Marquee>
         <small style={{color:"#19b5fe"}}><span style={{fontWeight:"bold"}}>"IMPORTANT NOTICE!"</span> We would like to inform you that Bluedart and Delhivery have revised their current price structure by 20% as part of the annual pricing revision. The same has been updated in your account as the current pricing. For more details, contact your sales SPOC/KAM or call us at 9654484998.</small>   
    </Marquee>
                <div className="page-header content-header d-flex flex-wrap flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center">
                    <div className="page-title">Order Summary</div>
                    <Form className="cardFilterSummary mr-4 mr-md-5">
                        <Row className="mx-sm-0 justify-content-end">
                            <Col sm={9}>
                                <FormGroup className='mb-sm-0'>
                                    <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} placement="bottomEnd" showOneCalendar={props.oneCalendar} placeholder="Choose Date Range" format="dd-MM-yyyy" />
                                </FormGroup>
                            </Col>
                            <Col sm={3} className="">
                                <Button className="ctm-btn btn-sm" color="primary" onClick={listingSummary}>Search</Button>
                            </Col>
                        </Row>
                    </Form>
                    
                </div>
                <CardBody className="p-0">
                    <span className='reload-card' onClick={listingSummary} title="Refresh"><i class="fa fa-refresh" aria-hidden="true"></i></span>
                    <Table responsive className="text-center custom-table mb-0">
                        <thead>
                            <tr>
                                <th className="col-1">Sno.</th>
                                <th className="col-4">Couriers</th>
                                <th>COD</th>
                                <th>Prepaid</th>
                                <th>Total</th>
                            </tr>
                            {checkData(uploadedSummaryData) &&
                                <tr>
                                    <th className="text-nowrap">Total </th>
                                    <th className="text-nowrap"></th>
                                    <th className="text-nowrap">{totalCod}</th>
                                    <th className="text-nowrap">{totalPrepaid}</th>
                                    <th className="text-nowrap">
                                        <div className="text-left mx-auto dowbload-con d-flex justify-content-between">{totalCodPrepaid}{totalCodPrepaid > 0 &&
                                            <Link to="#" className="download-icon mx-1" onClick={() => exportShipment(totalcourier_id, totalCodPrepaid)}><FontAwesomeIcon icon={faDownload} /></Link>}
                                        </div>
                                    </th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {checkData(uploadedSummaryData) ? uploadedSummaryData.sort((a, b) => a.cod > b.cod ? -1 : 1).map((item, index) => {
                                let codCount = checkUndeNullBlank(item.cod) ? item.cod : 0;
                                let prepaidCount = checkUndeNullBlank(item.prepaid) ? item.prepaid : 0;
                                let total = codCount + prepaidCount
                                return (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td> 
                                                <div style={{display:"flex", marginLeft:"50px"}}>
                                            <div style={{ width:"auto",display:"flex", alignItems:"center", justifyContent:"center" , marginRight:"5px"}}><img src={process.env.REACT_APP_IMAGE_COURIER + item.logo} alt="Courier Logo" style={{ width: '35px', height:"35px"}} /> </div>
                                            <div style={{ width:"85%",display:"flex", alignItems:"center"}}>{item.courier_name}</div>
                                            </div>
                                        </td>
                                        <td>{codCount}</td>
                                        <td>{prepaidCount}</td>
                                        <td>
                                            <div className="text-left mx-auto dowbload-con d-flex justify-content-between">
                                                {total}
                                                {total > 0 && <Link to="#" className="download-icon mx-1" onClick={() => exportShipment([item.courier_id], (item.delivery_type_id === 1 ? item.total : 0) + (item.delivery_type_id === 3 ? item.total : 0))}><FontAwesomeIcon icon={faDownload} /></Link>}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                                loadertable ? <tr>
                                    <td colSpan="5"><LoaderDefault /></td>
                                </tr>
                                    : ''
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </div>
    );
}

function mapDispatchToProps(state) {
    return {
        state: state.DataReducer
    }
}

export default connect(mapDispatchToProps)(OrderSummary);