import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Form, Label, Card, CardBody, CardSubtitle, Button, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { errorHandling } from '../../utlis';
import { baseString } from '../../config';

const EditOrder = (props) => {
  const [orderDetail, setOrderDetail] = useState({})
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // props.orderDetails.orderDetail.service_id = String(props.orderDetails.orderDetail.service_id);
    reset2(props.orderDetails.orderDetail)
    setOrderDetail(props.orderDetails.orderDetail)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { register: editOrderDetails, handleSubmit: orderDetailEditSubmit, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const onEditOrderDetailSubmit = (data) => {
    setLoader(true);
    data.merchant_id = props.merchantId
    data.order_id = data.id
    if (checkUndeNullBlank(props.orderDetails.orderDetail.system_tags)) {
      let text = props.orderDetails.orderDetail.system_tags;
      let result = text.match(/Short Address/g);
      if (checkUndeNullBlank(result) && result[0] === 'Short Address') {
        if (props.orderDetails.orderDetail.system_tags.indexOf("Address Updated") !== -1) {
          data.system_tags = props.orderDetails.orderDetail.system_tags;
        } else {
          data.system_tags = props.orderDetails.orderDetail.system_tags.replace("Short Address", "Address Updated");;
        }
      }
    } else {
      data.system_tags = "Address Updated";
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_CHANNEL_ORDER_EDIT}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data.error === false) {
        props.setEditmodal !== undefined && props.setEditmodal(false);
        props.setOrderDetail !== undefined && props.setOrderDetail({});
        // props.channelOrderListing !== undefined && props.channelOrderListing();
        reset2();
        Swal.fire({
          title: 'Success',
          text: res.data.msg,
          icon: 'success',
        })
      } else {
        setLoader(false);
        Swal.fire({ icon: "error", title: "Error", text: res.data.msg })
      }
    })
      .catch((err) => {
        errorHandling(err, "edit")
      });
  };

  const RtsOdrConfirm = (data) => {
    Swal.fire({
      html: '<h6 class="mb-0">Do you want to mark this Ready To Ship ?</h6>',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: 'confirm-swal-fortag-con rts-con'
    }).then((result) => {
      if (result.isConfirmed) {
        data.merchant_id = props.merchantId;
        data.order_id = data.id;

        if (checkUndeNullBlank(props.orderDetails.orderDetail.system_tags)) {
          if (props.orderDetails.orderDetail.system_tags.indexOf("Ready To Ship") !== -1) {
            data.system_tags = props.orderDetails.orderDetail.system_tags;
          } else {
            data.system_tags = props.orderDetails.orderDetail.system_tags + ",Ready To Ship";
          }
        } else {
          data.system_tags = "Ready To Ship";
        }
        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_ORDER_EDIT}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          setLoader(false);
          if (res.data.error === false) {
            props.setEditmodal !== undefined && props.setEditmodal(false);
            props.setOrderDetail !== undefined && props.setOrderDetail({});
            reset2();
            Swal.fire({
              title: 'Success',
              text: res.data.msg,
              icon: 'success',
              customClass: 'swal_scs_err_popup'
            })
          } else {
            setLoader(false);
            Swal.fire({ icon: "error", title: "Error", text: res.data.msg, customClass: 'swal_scs_err_popup', })
          }
        })
          .catch((err) => {
            errorHandling(err, "")
          });
      }
    })
  }

  const CancelOdrConfirm = () => {
    Swal.fire({
      html: '<h6 class="mb-0">Do you want to cancel order ?</h6>',
      input: 'textarea',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: 'Please Enter Remarks',
      },
      showLoaderOnConfirm: true,
      showCancelButton: true,
      confirmButtonText: 'Save',
      customClass: {
        popup: 'confirm-swal-fortag-con',
        input: 'form-control form-control-sm cancel-odr-remarks'
      },
      showLoaderOnConfirm: true,
      preConfirm: (inputVal) => {
        let data = {}
        data.order_ids = checkUndeNullBlank(props.orderDetails.orderDetail.id) ? [props.orderDetails.orderDetail.id] : 0;
        data.merchant_name = checkData(props.user) && props.user[0].name;
        data.merchant_id = checkData(props.user) && props.user[0].id;
        data.order_status_id = 2;
        data.remarks = inputVal;

        axios({
          method: "post",
          url: `${process.env.REACT_APP_CHANNEL_ORDER_UPDATE_STATUS}`,
          data: data,
          headers: { "token": window.btoa(baseString) }
        }).then((res) => {
          if (res.data.error === false) {
            props.setEditmodal !== undefined && props.setEditmodal(false);
            props.setOrderDetail !== undefined && props.setOrderDetail({});
            reset2();
            Swal.fire({ icon: 'success', text: res.data.msg, title: 'Success', customClass: 'swal_scs_err_popup', });
          } else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', customClass: 'swal_scs_err_popup', })
          }
        }).catch((err) => {
          errorHandling(err, "");
        });
      },
    })
  }

  return (
    <>
      {loader && <div className="formLoader"><Spinner /></div>}
      <Form onSubmit={orderDetailEditSubmit(onEditOrderDetailSubmit)}>
        <Row className="mx-0 justify-content-center addModal">
          <Col sm={12} className="mb-3 d-flex justify-content-end">
            <Button className="btn btn-sm mr-2" color="success" onClick={orderDetailEditSubmit(RtsOdrConfirm)} style={{ width: "115px"}}>Ready To Ship</Button>
            <Button className="btn btn-sm" color="danger" onClick={() => CancelOdrConfirm()} style={{ width: "115px" }}>Cancel</Button>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardSubtitle tag="h6" className="mb-3">Order Detail</CardSubtitle>
                <FormGroup>
                  <Label for="Courier">Choose Courier</Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editOrderDetails("courier_id")} className="form-control-sm form-control">
                      <option value="">Choose Courier</option>
                      {checkData(props.orderDetails.merchantCouriers) && props.orderDetails.merchantCouriers.map((item, index) => (
                        <option value={item.id}>{item.CourierName}</option>
                      ))}
                    </select>

                  </div>
                  {errors2.courier_id && <span className="text-danger d-block error">{errors2.courier_id.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="pickup_point">Choose Pickup point</Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editOrderDetails("pickup_point_id")} className="form-control-sm form-control">
                      <option value="">Choose Pickup Point</option>
                      {checkData(props.orderDetails.merchantPickupPoints) && props.orderDetails.merchantPickupPoints.map((item, index) => (
                        <option value={item.id}>{item.MerchantPickupPointName}</option>
                      ))}
                    </select>

                  </div>
                  {errors2.pickup_point_id && <span className="text-danger d-block error">{errors2.pickup_point_id.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="brand">Choose Brand</Label>
                  <div className="position-relative">
                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                    <select {...editOrderDetails("brand_id")} id="brand" className="form-control-sm form-control">
                      <option value="">Choose Brand</option>
                      {checkData(props.orderDetails.merchantBrands) && props.orderDetails.merchantBrands.map((item, index) => (
                        <option value={item.id}>{item.MerchantBrandName}</option>
                      ))}
                    </select>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="order_no">Enter Order Number<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("order_no", { required: 'Please Enter Order Number' })} className="form-control-sm form-control" placeholder="Enter Order Number" id="order_no" />
                  {errors2.order_no && <span className="text-danger d-block error">{errors2.order_no.message}</span>}
                </FormGroup>
                {checkData(orderDetail) && orderDetail.awb_no === '' && props.user[0].user_type === "Super" &&
                  <FormGroup>
                    <Label for="awb_number">AWB Number*</Label>
                    <input  {...editOrderDetails("awb_no")} className="form-control-sm form-control" placeholder="Enter AWB Number" id="awb_no" />
                    {errors2.awb_no && <span className="text-danger d-block error">{errors2.awb_no.message}</span>}
                  </FormGroup>
                }
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardSubtitle tag="h6" className="mb-3">Product Detail</CardSubtitle>
                <FormGroup>
                  <Label for="product_name">Enter Product Name<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("product_name", { required: 'Please Enter Product Name' })} className="form-control-sm form-control" placeholder="Enter Product Name" id="product_name" />
                  {errors2.product_name && <span className="text-danger d-block error">{errors2.product_name.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="product_display_name">Product Display Name</Label>
                  <input  {...editOrderDetails("product_display_name", { required: 'Please Enter Product Display Name' })} className="form-control-sm form-control" placeholder="Enter Product Name" id="product_display_name" />
                  {errors2.product_display_name && <span className="text-danger d-block error">{errors2.product_display_name.message}</span>}
                </FormGroup>

                <FormGroup>
                  <Label for="qty">Enter Product Quantity<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("product_qty", {
                    required: 'Please Enter Quantity',
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Quantity' }
                  })} className="form-control-sm form-control" placeholder="Enter Product Quantity" id="qty" />
                  {errors2.product_qty && <span className="text-danger d-block error">{errors2.product_qty.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="prcode">Enter Product Code</Label>
                  <input  {...editOrderDetails("product_code")} className="form-control-sm form-control" placeholder="Enter Product Code" id="prcode" />
                </FormGroup>
                <FormGroup>
                  <Label for="prcategory">Enter Product Category</Label>
                  <input  {...editOrderDetails("product_category")} className="form-control-sm form-control" placeholder="Enter Product Category" id="prcategory" />
                </FormGroup>
                <FormGroup>
                  <Label for="invoice_value">Enter Invoice Value<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("product_invoice_value", {
                    required: 'Please Enter Invoice Value',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Invoice Value' }
                  })} className="form-control-sm form-control" placeholder="Enter Invoice Value" id="invoice_value" />
                  {errors2.product_invoice_value && <span className="text-danger d-block error">{errors2.product_invoice_value.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="COD_value">Enter COD Value<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("product_cod_value", {
                    required: 'Please Enter COD Value',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid COD Value' }
                  })} className="form-control-sm form-control" placeholder="Enter COD Value" id="COD_value" />
                  {errors2.product_cod_value && <span className="text-danger d-block error">{errors2.product_cod_value.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="product_weight">Enter Product Weight(KGS)<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("product_weight_in_kgs", {
                    required: 'Please Enter Product Weight(KGS)',
                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Product Weight(KGS)' }
                  })} className="form-control-sm form-control" placeholder="Enter Product Weight" id="product_weight" />
                  {errors2.product_weight_in_kgs && <span className="text-danger d-block error">{errors2.product_weight_in_kgs.message}</span>}
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="product_dimension">Enter Product Dimension(CMS)<span className="text-danger">*</span></Label>
                  <div className="d-flex align-items-center">
                    <Label for="prod_l" className="mx-2">L</Label>
                    <input  {...editOrderDetails("length_in_cms", {
                      required: 'Please Enter Product Length',
                      pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Product Length' }
                    })} className="form-control-sm form-control " id="prod_l" />
                    <Label for="prod_b" className="mx-2">B</Label>
                    <input  {...editOrderDetails("breadth_in_cms", {
                      required: 'Please Enter Product Breadth',
                      pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Product Breadth' }
                    })} className="form-control-sm form-control " id="prod_b" />
                    <Label for="prod_h" className="mx-2">H</Label>
                    <input  {...editOrderDetails("height_in_cms", {
                      required: 'Please Enter Product Height',
                      pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Product Height' }
                    })} className="form-control-sm form-control " id="prod_h" />
                  </div>
                  {errors2.length_in_cms && <span className="text-danger d-block error">{errors2.length_in_cms.message}</span>}
                  {errors2.breadth_in_cms && <span className="text-danger d-block error">{errors2.breadth_in_cms.message}</span>}
                  {errors2.height_in_cms && <span className="text-danger d-block error">{errors2.height_in_cms.message}</span>}
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4}>
            <Card>
              <CardBody>
                <CardSubtitle tag="h6" className="mb-3">Customer Detail</CardSubtitle>
                <FormGroup>
                  <Label for="customer_name">Customer Name<span className="text-danger">*</span></Label>
                  <input {...editOrderDetails("customer_name", {
                    required: 'Please Enter Customer Name',
                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid Customer Name' }
                  })} className="form-control-sm form-control" placeholder="Customer Name" id="customer_name" />
                  {errors2.customer_name && <span className="text-danger d-block error">{errors2.customer_name.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="address1">Address 1<span className="text-danger">*</span></Label>
                  <textarea {...editOrderDetails("customer_address_1", {
                    required: 'Please Enter Address 1',
                    minLength: { value: 15, message: "Please Enter at least 15 Characters" },
                    maxLength: { value: 255, message: "Only Enter Maximum 255 Characters" }
                  })} type="textarea" className="form-control-sm form-control" placeholder="Address 1" rows="2" id="address1" />
                  {errors2.customer_address_1 && <span className="text-danger d-block error">{errors2.customer_address_1.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="address2">Address 2</Label>
                  <textarea {...editOrderDetails("customer_address_2")} className="form-control-sm form-control" placeholder="Address 2" rows="2" id="address2" />
                </FormGroup>
                <FormGroup>
                  <Label for="city">Enter City<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("customer_city", {
                    required: 'Please Enter City Name',
                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City Name' }
                  })} className="form-control-sm form-control" placeholder="Enter City" id="city" />
                  {errors2.customer_city && <span className="text-danger d-block error">{errors2.customer_city.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="state">Enter State<span className="text-danger">*</span></Label>
                  <input  {...editOrderDetails("customer_state", {
                    required: 'Please  Enter State Name',
                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid State Name' }
                  })} className="form-control-sm form-control" placeholder="Enter State" id="state" />
                  {errors2.customer_state && <span className="text-danger d-block error">{errors2.customer_state.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="mobile">Enter Mobile No.<span className="text-danger">*</span></Label>
                  <input {...editOrderDetails("customer_mobile", {
                    required: 'Please Enter Mobile No.',
                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                  })} className="form-control-sm form-control" placeholder="Enter Mobile No." id="mobile" />
                  {errors2.customer_mobile && <span className="text-danger d-block error">{errors2.customer_mobile.message}</span>}
                </FormGroup>
                <FormGroup>
                  <Label for="alt_no">Enter Alternate No.</Label>
                  <input {...editOrderDetails("customer_alt_no", {
                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Alternate Number' }
                  })} className="form-control-sm form-control" placeholder="Enter Alternate No." id="alt_no" />
                  {errors2.customer_alt_no && <span className="text-danger d-block error">{errors2.customer_alt_no.message}</span>}
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="pincode">Enter Pincode<span className="text-danger">*</span></Label>
                  <input {...editOrderDetails("customer_pincode", {
                    required: 'Please Enter Pincode',
                    minLength: { value: 6, message: "at least 6 digits" },
                    maxLength: { value: 6, message: "maximum 6 digits" },
                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                  })} className="form-control-sm form-control" placeholder="Enter Pincode" id="pincode" />
                  {errors2.customer_pincode && <span className="text-danger d-block error">{errors2.customer_pincode.message}</span>}
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
          <Col sm={12} className="text-right mt-3">
            <Button className="ctm-btn btn-sm" color="primary">Save</Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default EditOrder;