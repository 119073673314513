import React, { useState, useEffect } from 'react';
import { Row, Table, Col, Card, CardBody, CardTitle, FormGroup, Form, CardHeader, Label, Button, Collapse, Spinner, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { ArrowLeft } from 'react-feather';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString, order_ndrs, states } from '../../config';
import { randomNumberByDate, errorHandling } from '../../utlis';
import LoaderDefault from '../LoaderDefault';
import { useHistory } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { StateList } from '../manageMasters/StateList';

const CreateOrder = (props) => {

    const [isCod, setIsCod] = useState(false);
    const [codValue,setCodValue] = useState("");
    // console.log(codValue,"bitt")
    const [kyc, setKyc] = useState("");
    const [kycNoValid, setKycNoValid] = useState(false);
    const [isWalkinCustomer, setIsWalkinCustomer] = useState(false);
    const [loader, setLoader] = useState(false);
    const [courierwithPrice, setCourierwithPrice] = useState({});
    const [pickupLocation, setPickupLocation] = useState({});
    const [pickupKey, setPickupKey] = useState('');
    const [zoneAndPrice, setZoneAndPrice] = useState({});
    const [quantity, setQuantity] = useState('');
    const [unitprice, setUnitprice] = useState('');
    const [noData, setNoData] = useState(false);
    const [ewayNumber, setEwayNumber] = useState('');
    const [multiple_product, setProducts] = useState([{ id: 1 }]);
    //  console.log(multiple_product,"p")
    const [totalPrice, setTotalPrice] = useState(0);
    

     
    const handleInputChange = (index, field, value) => {
        const updatedProducts = [...multiple_product];
        updatedProducts[index][field] = value;
        setProducts(updatedProducts);
      };

    

    const addProduct = () => {
        if (multiple_product.length < 5) {
          setProducts([...multiple_product, { id: multiple_product.length + 1 }]);
        }
      };

      const removeProduct = () => {
        if (multiple_product.length > 1) {
            const updatedProducts = multiple_product.slice(0, -1);
            setProducts(updatedProducts);
        }
      };

      

      useEffect(() => {
        const total = multiple_product.reduce((sum, multiple_product) => {
          const qty = parseInt(multiple_product.qty || "0", 10);
          const unitPrice = parseFloat(multiple_product.price || "0");
          return sum + qty * unitPrice;
        }, 0);
        setTotalPrice(total);
      }, [multiple_product]);
    


    // let inVoiceValue = quantity * unitprice

    const scrollTo = () => {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }
    const handlePickuplocation = (e) => {
        let index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        var pickupIndex = optionElement.getAttribute('data-id');
        setPickupKey(pickupIndex)
    }
    const history = useHistory();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const walkinDetail = (e) => {
        if (e.target.value.length === 10) {
            let mydata = {}
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_mobile": e.target.value }
                // walkin_mobile
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_WALKIN_CUST_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "walkin_mobile": checkUndeNullBlank(res.data.data[0].mobile) ? res.data.data[0].mobile : "", "walkin_full_name": checkUndeNullBlank(res.data.data[0].name) ? res.data.data[0].name : "", "walkin_email": checkUndeNullBlank(res.data.data[0].email) ? res.data.data[0].email : "", "walkin_kyc": checkUndeNullBlank(res.data.data[0].kyc_type_id) ? res.data.data[0].kyc_type_id : "", "walkin_kyc_no": checkUndeNullBlank(res.data.data[0].kyc_no) ? res.data.data[0].kyc_no : "" }
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'walkin_full_name', value: myResponse.walkin_full_name },
                                    { name: 'walkin_email', value: myResponse.walkin_email },
                                    { name: 'walkin_kyc', value: myResponse.walkin_kyc },
                                    { name: 'walkin_kyc_no', value: myResponse.walkin_kyc_no }
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }
    }


    const destinationDetails = (e) => {
        if (e.target.value.length === 10) {
            let mydata = {}
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_mobile": e.target.value }
                // walkin_mobile
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_DEST_CUST_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "customer_name": checkUndeNullBlank(res.data.data[0].customer_name) ? res.data.data[0].customer_name : "", "customer_mobile": e.target.value, "customer_address_1": checkUndeNullBlank(res.data.data[0].customer_address_1) ? res.data.data[0].customer_address_1 : "", "customer_address_2": checkUndeNullBlank(res.data.data[0].customer_address_2) ? res.data.data[0].customer_address_2 : "", "destination_pincode": checkUndeNullBlank(res.data.data[0].destination_pincode) ? res.data.data[0].destination_pincode : "", "customer_city": checkUndeNullBlank(res.data.data[0].customer_city) ? res.data.data[0].customer_city : "", "customer_state": checkUndeNullBlank(res.data.data[0].customer_state) ? res.data.data[0].customer_state : "", "customer_email": checkUndeNullBlank(res.data.data[0].customer_email) ? res.data.data[0].customer_email : "" }
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'customer_name', value: checkUndeNullBlank(myResponse.customer_name) ? myResponse.customer_name : '' },
                                    { name: 'customer_address_1', value: checkUndeNullBlank(myResponse.customer_address_1) ? myResponse.customer_address_1 : '' },
                                    { name: 'cus tomer_address_2', value: checkUndeNullBlank(myResponse.customer_address_2) ? myResponse.customer_address_2 : '' },
                                    { name: 'destination_pincode', value: checkUndeNullBlank(myResponse.destination_pincode) ? myResponse.destination_pincode : '' },
                                    { name: 'customer_city', value: checkUndeNullBlank(myResponse.customer_city) ? myResponse.customer_city : '' },
                                    { name: 'customer_state', value: checkUndeNullBlank(myResponse.customer_state) ? myResponse.customer_state : '' },
                                    { name: 'customer_emai', value: checkUndeNullBlank(myResponse.customer_emai) ? myResponse.customer_emai : '' }
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }
    }

    const GetCityState = (e) => {
        if (e.target.value.length === 6) {
            let mydata = {}
            if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
                mydata = { "merchant_id": props.user[0].id, "search_pincode": e.target.value }
                // pincode
            }
            axios({
                method: "post",
                url: `${process.env.REACT_APP_DEST_PINCODE_DETAIL}`,
                data: mydata,
                headers: { "token": window.btoa(baseString) }
            })
                .then((res) => {
                    if (res.data !== undefined && res.data.fatal !== true) {
                        if (res.data.error === false && checkData(res.data.data)) {
                            let myResponse = {}
                            myResponse = { "customer_city": checkUndeNullBlank(res.data.data[0].name) ? res.data.data[0].name : "",  "customer_state": checkUndeNullBlank(res.data.data[0].state) ? res.data.data[0].state : ""}
                            // reset(myResponse)
                            if (checkData(myResponse)) {
                                [
                                    { name: 'customer_city', value: checkUndeNullBlank(myResponse.customer_city) ? myResponse.customer_city : '' },
                                    { name: 'customer_state', value: checkUndeNullBlank(myResponse.customer_state) ? myResponse.customer_state : '' },
                                ].forEach(({ name, value }) => setValue(name, value))
                            }
                        }
                    }
                }).catch((err) => {
                    errorHandling(err, "")
                });
        }



    };
    useEffect(() => {
        let mydata = {}
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            mydata = { "merchant_id": props.user[0].id }
        }
        axios({
            method: "post",
            url: `${process.env.REACT_APP_MERCHANT_ACTIVE_PICKUPPOINT}`,
            data: mydata,
            headers: { "token": window.btoa(baseString) }
        })
            .then((res) => {
                if (res.data !== undefined && res.data.fatal !== true) {
                    if (parseInt(res.data.errno) > 0) {
                        if (res.data.sqlMessage !== undefined) {
                            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
                        }
                    } else {
                        if (res.data.error === false) {
                            setPickupLocation(res.data.data);
                        }
                    }
                }
            }).catch((err) => {
                errorHandling(err, "")
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const inputChange = (e) => {
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        // if (parseInt(e.target.value) === 3) {
        //     [
        //         { name: 'cod_value', value: '' }
        //     ].forEach(({ name, value }) => setValue(name, value))
        // }
        setZoneAndPrice({});
        setCourierwithPrice({});
    }
    const inputChangeDtype = (e) => {
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        if (parseInt(e.target.value) === 3) {
            [
                { name: 'cod_value', value: '' }
            ].forEach(({ name, value }) => setValue(name, value))
        }
        setZoneAndPrice({});
        setCourierwithPrice({});
    }
    const onSubmitPrice = (data) => {
        // setInvoiceValue(data.product_qty*data.product_unit_price)
        [
            { name: 'courier_id', value: '' }
        ].forEach(({ name, value }) => setValue(name, value));
        setZoneAndPrice({});
        setCourierwithPrice({});
        setLoader(true);
        let formData = new FormData();
        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            formData.append("merchant_id", props.user[0].id);
            formData.append("admin_id", process.env.REACT_APP_ADMIN_ID);
        }
        formData.append("delivery_type_id", data.delivery_type_id);
        formData.append("cod_value", checkUndeNullBlank(data.cod_value) && parseInt(data.delivery_type_id) === 1 ? data.cod_value : '');
        formData.append("source_pincode", pickupLocation[pickupKey].pickup_pincode);
        formData.append("destination_pincode", data.destination_pincode);
        formData.append("weight_in_kgs", data.weight_in_kgs);
        formData.append("length_in_cms", data.length_in_cms);
        formData.append("breadth_in_cms", data.breadth_in_cms);
        formData.append("height_in_cms", data.height_in_cms);
        formData.append("service_id", data.service_id);
        axios({
            method: "post",
            url: `${process.env.REACT_APP_PRICE_CALCULATOR}`,
            data: formData,
            headers: { "token": window.btoa(baseString) }
        })
            .then(function (response) {
                setLoader(false);
                if (response.data.error === false) {
                    setCourierwithPrice(response.data.data)
                    scrollTo();
                    setNoData(false);
                    if (response.data.data.length < 1) {
                        setNoData(true);
                    }
                }
                if (response.data.error === true) {
                    Swal.fire({
                        title: 'Error',
                        text: response.data.msg,
                        icon: 'error',
                    })
                }
            })
            .catch(function (response) {
                setLoader(false);
                errorHandling(response, "")
            });
    }
    const onSubmitOrder = (data) => {



        // console.log(data);
        if (data.service_id === "6") {
            data.movement_type_id = '2'
        } else {
            data.movement_type_id = '1'
        }
        
        const filteredProducts = multiple_product.filter(
            (multiple_product) => multiple_product.name && multiple_product.qty && multiple_product.price
        );

        data.multiple_product = filteredProducts;
        
        data.product_name = multiple_product[0].name
        data.product_qty = multiple_product[0].qty
        data.product_unit_price = multiple_product[0].price

        if (props.user !== undefined && props.user !== null && props.user[0].user_type === "Merchant") {
            data.merchant_id = props.user[0].id
            data.admin_id = process.env.REACT_APP_ADMIN_ID
        } else {
            data.admin_id = props.user[0].id
        }
        // let inValue = totalPrice;
        setLoader(true);
        if (checkData(data.courier_id)) {
            if (parseInt(data.courier_id) === 47 && parseInt(inValue) > 250000) {
                setLoader(false);
                Swal.fire({ title: 'Error', text: `Invoice Value will be less than 250000`, icon: 'error' })
            } else {
                let diffrence_balance = (parseInt(zoneAndPrice.total_price) > 0 && parseInt(props.user[0].wallet_balance) > 0) ? (parseInt(zoneAndPrice.total_price) - parseInt(props.user[0].wallet_balance)) : 0;
                // if (checkData(props.user) && props.user[0].wallet_balance >= zoneAndPrice.total_price) {
                data.invoice_value = totalPrice;
                // console.log(totalPrice,"tp")
                data.merchant_wallet_balance = parseFloat(props.user[0].wallet_balance);
                data.all_charges = zoneAndPrice.all_prices
                data.freight = zoneAndPrice.total_price
                data.zone_category_id = zoneAndPrice.zone_id
                data.pickup_pincode = pickupLocation[pickupKey].pickup_pincode !== undefined ? pickupLocation[pickupKey].pickup_pincode : '';
                data.ewayNumber=ewayNumber;
                
                axios({
                    method: "post",
                    url: `${process.env.REACT_APP_CREATE_SINGEL_ORDER}`,
                    data: data,
                    headers: { "token": window.btoa(baseString) }
                })
                    .then(function (response) {
                        setLoader(false);
                        if (response.data.error === false) {
                            Swal.fire({ title: 'Success', text: response.data.msg, icon: 'success', })
                            if (response.data.status === 'assigned') {
                                history.push(`/order-detail/${response.data.order_id}`);
                            } if (response.data.status === 'failed') {
                                history.push(`/orders?o=failed`);
                            } if (response.data.status === 'processing') {
                                history.push(`/orders?o=processing}`);
                            }
                        }
                        if (response.data.error === true) {
                            Swal.fire({ title: 'Error', text: response.data.msg, icon: 'error', allowOutsideClick: false, allowEscapeKey: false });
                        }
                    })
                    .catch(function (response) {
                        setLoader(false);
                        errorHandling(response, "")
                    });
                // }
                // else{
                //     setLoader(false);
                //     Swal.fire({title:'Error', text:`Please Recharge Your Wallet With Amount ${diffrence_balance.toFixed(2)}. Your Current Balance is  ${props.user[0].wallet_balance} `, icon:'error'})
                // }
            }
        } else {
            setLoader(false);
            Swal.fire({ title: 'Error', text: `Please Choose Courier`, icon: 'error' })
        }
    }

    const onErrorUpdate = (errors, e) => console.log('Error', errors);

    const getKycId = (e) => {
        setKyc(e.target.selectedOptions[0].text);
        if (kyc !== "Select Kyc" || kyc !== "") {
            setKycNoValid(true);
        }
    }

    return (
        <div className="app-content content overflow-hidden">
            {loader && <div className="formLoader"><Spinner /></div>}
            <Row>
                <Col sm={12}>
                    <div className="p-0 my-3 CreateOrder content-wrapper border-0">
                        <Form>
                            <Card className="custom-shadow border-0">
                                <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                                    <CardTitle className="page-title">Create Order</CardTitle>
                                    <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                                        <Link to="/orders" className="btn-text btn-light"><span className="icon"><ArrowLeft /></span><span>Back to Uploaded List</span></Link>
                                    </div>
                                </CardHeader>
                                <CardBody className="forms">
                                    <Row>
                                        <Col md={4} lg={4}>
                                            <CardHeader className="px-0"><CardTitle>Choose Customer Type</CardTitle></CardHeader>
                                            <FormGroup>
                                                <div className="d-flex flex-wrap">
                                                    <span className=" mr-2">
                                                        <input {...register("customer_type", { required: true })} type="radio" value="2" id="merchant" onClick={() => setIsWalkinCustomer(false)} onChange={(e) => inputChange(e)} defaultChecked />
                                                        <Label className="mx-2" for="merchant">Regular Merchant</Label>
                                                    </span>
                                                    <span className="">
                                                        <input {...register("customer_type", { required: true })} type="radio" value="1" id="customer" onClick={() => setIsWalkinCustomer(true)} onChange={(e) => inputChange(e)} />
                                                        <Label className="mx-2" for="customer">Walk-In Customer</Label>
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4} lg={4}>
                                            <CardHeader className="px-0"><CardTitle>Choose Delivery Type</CardTitle></CardHeader>
                                            <FormGroup>
                                                <div className="d-flex">
                                                    <span className=" mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="3" id="prepaid" defaultChecked onClick={() => setIsCod(false)} onChange={(e) => inputChangeDtype(e)} />
                                                        <Label className="mx-2" for="prepaid">Prepaid</Label>
                                                    </span>
                                                    <span className=" mr-2">
                                                        <input {...register("delivery_type_id", { required: true })} type="radio" value="1" id="cod" onClick={() => setIsCod(true)} onChange={(e) => inputChangeDtype(e)} />
                                                        <Label className="mx-2" for="cod">COD</Label>
                                                    </span>
                                                    <Collapse isOpen={isCod}>
                                                        {isCod &&
                                                            <input {...register("cod_value", { 
                                                                required: 'Please Enter COD Amount',
                                                                min: { value: 1, message: "Please enter a value greater than or equal to 1." },
                                                                pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid COD Value' }    
                                                            })} className="form-control-sm form-control" placeholder="Enter COD Amount" id="cod_amount" style={{ width: "170px" }}  onChange={(e) => { inputChange(e); setCodValue(e.target.value); }}/>
                                                        }
                                                    </Collapse>
                                                    {/* onChange={(e) => inputChange(e)} */}
                                                </div>
                                                {errors.cod_value && <span className="text-danger d-block error">{errors.cod_value.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4} lg={4}>
                                            <CardHeader className="px-0"><CardTitle className="px-0">Service</CardTitle></CardHeader>
                                            <FormGroup>
                                                <div className="d-flex flex-wrap">
                                                    <span className=" mr-2">
                                                        <input {...register("service_id", { required: true })} type="radio" value="1" id="express" onChange={(e) => inputChange(e)}  />
                                                        <Label className="mx-2" for="express">Express</Label>
                                                    </span>
                                                    <span className="">
                                                        <input {...register("service_id", { required: true })} type="radio" value="6" id="reverse" onChange={(e) => inputChange(e)} />
                                                        <Label className="mx-2" for="reverse">Reverse</Label>
                                                    </span>
                                                    <span className="">
                                                        <input {...register("service_id", { required: true })} type="radio" value="7" id="surface" onChange={(e) => inputChange(e)} defaultChecked/>
                                                        <Label className="mx-2" for="surface">Surface</Label>
                                                    </span>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {isWalkinCustomer &&
                                <Collapse isOpen={isWalkinCustomer}>
                                    <Card className="custom-shadow border-0">
                                        <CardBody>
                                            <Row>
                                                <Col sm={12}>
                                                    <CardHeader className="px-0"><CardTitle>Walk In Customer Details</CardTitle></CardHeader>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="mobile">Mobile<span className="text-danger">*</span> <small>eg.987654321</small></Label>
                                                        <input {...register("walkin_mobile", {
                                                            required: 'Please Enter Mobile No.',
                                                            minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                            maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                            pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                                                        })} className="form-control-sm form-control" placeholder="Enter Mobile" id="mobile" onChange={(e) => { inputChange(e); walkinDetail(e); }} />
                                                        {errors.walkin_mobile && <span className="text-danger d-block error">{errors.walkin_mobile.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="full_name">Full Name<span className="text-danger">*</span></Label>
                                                        <input {...register("walkin_full_name", {
                                                            required: 'Please Enter Full Name',
                                                            pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid Name' }
                                                        })} className="form-control-sm form-control" placeholder="Full Name" id="walkin_full_name" onChange={(e) => inputChange(e)} />
                                                        {errors.walkin_full_name && <span className="text-danger d-block error">{errors.walkin_full_name.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <input {...register("walkin_email", {
                                                            required: false,
                                                            pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                                                        })} className="form-control-sm form-control" placeholder="Email" id="email" onChange={(e) => inputChange(e)} />
                                                        {errors.walkin_email && <span className="text-danger d-block error">{errors.walkin_email.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="kyc">KYC <small>eg.Choose Adhar,Voter ID, Driving License</small></Label>
                                                        <div className="position-relative">
                                                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                            <select {...register("walkin_kyc")} className="form-control-sm form-control" id="kyc" onChange={(e) => { inputChange(e); getKycId(e); }} >
                                                                <option value="">Select Kyc</option>
                                                                <option value="1">Adhar Card</option>
                                                                <option value="2">Voter ID</option>
                                                                <option value="3">Driving License</option>
                                                            </select>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="valid_id">Enter Valid {kyc !== "" ? kyc : "KYC Number"}</Label>
                                                        <input {...register("walkin_kyc_no", { required: kycNoValid ? 'Please Select Pickup Location' : false })} className="form-control-sm form-control" placeholder={kyc} id="walkin_kyc_no" onChange={(e) => inputChange(e)} />
                                                        {errors.walkin_kyc_no && <span className="text-danger d-block error">{errors.walkin_kyc_no.message}</span>}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            }
                            <Card className="custom-shadow border-0">
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <CardHeader className="px-0"><CardTitle>Pickup Details</CardTitle></CardHeader>
                                            <FormGroup>
                                                <Label for="pickup_detail">Choose Pickup Location<span className="text-danger">*</span></Label>
                                                <div className="position-relative">
                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                    <select {...register("pickup_point_id", { required: 'Please Select Pickup Location' })} className="form-control-sm form-control" id="pickup_point_id" onChange={(e) => { inputChange(e); handlePickuplocation(e); }}>
                                                        <option value="">Select Pickup Location</option>
                                                        {pickupLocation !== undefined && pickupLocation !== null && Object.entries(pickupLocation).length > 0 ? pickupLocation.map((item, index) => (
                                                            <option data-id={index} value={item.id}>{item.contact_name}</option>
                                                        ))
                                                            : ''}
                                                    </select>
                                                </div>
                                                {errors.pickup_point_id && <span className="text-danger d-block error">{errors.pickup_point_id.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <CardHeader className="px-0"><CardTitle>Pickup Address</CardTitle></CardHeader>
                                            <FormGroup>
                                                {checkData(pickupLocation) && checkData(pickupKey) ?
                                                    <p className="mb-0">{checkData(pickupLocation[pickupKey].contact_name) && pickupLocation[pickupKey].contact_name},<br /><span className="mr-3">{checkData(pickupLocation[pickupKey].pickup_address_1) && pickupLocation[pickupKey].pickup_address_1}</span>{checkData(pickupLocation[pickupKey].pickup_city_name) && pickupLocation[pickupKey].pickup_city_name} <br /> {states[pickupLocation[pickupKey].pickup_state_id]} , {pickupLocation[pickupKey].pickup_pincode}</p> : 'Please Choose Pickup Location First'
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="custom-shadow border-0">
                                <CardBody>
                                    <Row>
                                        <Col sm={12}>
                                            <CardHeader className="px-0"><CardTitle>Destination Details</CardTitle></CardHeader>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="customer_mobile">Contact Mobile<span className="text-danger">*</span><small>eg.987654321</small></Label>
                                                <input {...register("customer_mobile", {
                                                    required: 'Please Enter Mobile Number',
                                                    minLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                    maxLength: { value: 10, message: "Please Enter 10 Digit Mobile Number" },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Mobile Number' }
                                                })} className="form-control-sm form-control" placeholder="Contact Mobile" id="customer_mobile" onChange={(e) => { inputChange(e); destinationDetails(e); }} />
                                                {errors.customer_mobile && <span className="text-danger d-block error">{errors.customer_mobile.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="customer_name">Contact Name<span className="text-danger">*</span></Label>
                                                <input {...register("customer_name", {
                                                    required: 'Please Enter Contact Name',
                                                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid Contact Name' }
                                                })} type="text" className="form-control-sm form-control" placeholder="Full Name" onChange={(e) => inputChange(e)} />
                                                {errors.customer_name && <span className="text-danger d-block error">{errors.customer_name.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="address1">Address 1<span className="text-danger">*</span></Label>
                                                <input {...register("customer_address_1", { required: 'Please Enter Address 1.' })} className="form-control-sm form-control" placeholder="Enter Address 1" onChange={(e) => inputChange(e)} />
                                                {errors.customer_address_1 && <span className="text-danger d-block error">{errors.customer_address_1.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="address2">Address 2 <small> eg.Area name</small></Label>
                                                <input {...register("customer_address_2")} className="form-control-sm form-control" placeholder="Enter Address 2" onChange={(e) => inputChange(e)} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="destination_pincode">Pincode<span className="text-danger">*</span></Label>
                                                <input {...register("destination_pincode", {
                                                    required: 'Please Enter Pincode.',
                                                    minLength: { value: 6, message: "at least 6 digits" },
                                                    maxLength: { value: 6, message: "maximum 6 digits" },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Pincode' }
                                                })} className="form-control-sm form-control" placeholder="Enter Pincode" onChange={(e) => { inputChange(e); GetCityState(e); }}  />
                                                {errors.destination_pincode && <span className="text-danger d-block error">{errors.destination_pincode.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="city">City<span className="text-danger">*</span></Label>
                                                <input {...register("customer_city", {
                                                    required: 'Please Enter City.',
                                                    pattern: { value: /^[a-z ,.'-]+$/i, message: 'Please Enter Valid City' }
                                                })} className="form-control-sm form-control" placeholder="Enter City" onChange={(e) => inputChange(e)} />
                                                {errors.customer_city && <span className="text-danger d-block error">{errors.customer_city.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="state">Select State<span className="text-danger">*</span></Label>
                                                <div className="position-relative">
                                                    <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                                                    <select {...register("customer_state", { required: 'Please Select State' })} className="form-control-sm form-control" onChange={(e) => inputChange(e)}>
                                                        <option value="">Select State</option>
                                                        {StateList.map((item, index) => (
                                                            <option value={item.state_name}>{item.state_name}</option>
                                                        ))
                                                        }
                                                    </select>
                                                    {errors.customer_state && <span className="text-danger d-block error">{errors.customer_state.message}</span>}
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <input {...register("customer_email", {
                                                    required: false,
                                                    pattern: { value: /\S+@\S+\.\S+/, message: 'Please Enter Valid Email' }
                                                })} className="form-control-sm form-control" placeholder="Enter Email" onChange={(e) => inputChange(e)} />
                                                {errors.customer_email && <span className="text-danger d-block error">{errors.customer_email.message}</span>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Card className="custom-shadow border-0">
                                <CardBody>
                                    <Row className="align-items-baseline">
                                        <Col sm={12}>
                                            <CardHeader className="px-0"><CardTitle>Package Information</CardTitle></CardHeader>
                                        </Col>
                                        {/* //Bittu */}
                                        {multiple_product.map((product, index) => (
                                         <Row key={index} className="mb-0">
                                           <Col md={8}>
                                             <FormGroup>
                                               <Label for={`name_${index}`}>
                                                {product.id}.  Product Name<span className="text-danger">*</span>
                                               </Label>
                                               <input
                                                 {...register(`multiple_product[${index}].name`, {
                                                   required: "Enter Product Name",
                                                   maxLength: { value: 60, message: "Please Enter below 60 words" },
                                                 })}
                                                 className="form-control-sm form-control"
                                                 placeholder="Enter Product Name"
                                                 value={product.name}
                                                 onChange={(e) =>
                                                   handleInputChange(index, "name", e.target.value)
                                                 }
                                               />
                                               {errors.multiple_product?.[index]?.name && (
                                                 <span className="text-danger d-block error">
                                                   {errors.multiple_product[index].name.message}
                                                 </span>
                                               )}
                                             </FormGroup>
                                           </Col>
                                           <Col md={2}>
                                             <FormGroup>
                                               <Label for={`qty_${index}`}>
                                                 Quantity<span className="text-danger">*</span>
                                               </Label>
                                               <input
                                                 {...register(`multiple_product[${index}].qty`, {
                                                   required: "Enter Quantity",
                                                   min: { value: 1, message: "Value must be >= 1" },
                                                   pattern: {
                                                     value: /^[+-]?\d+$/,
                                                     message: "Please Enter Valid Quantity",
                                                                                    },
                                                 })}
                                                 className="form-control-sm form-control"
                                                 placeholder="Enter Product QTY"
                                                 id={`qty_${index}`}
                                                 value={product.qty}
                                                 onChange={(e) =>
                                                   handleInputChange(index, "qty", e.target.value)
                                                 }
                                               />
                                               {errors.multiple_product?.[index]?.qty && (
                                                 <span className="text-danger d-block error">
                                                   {errors.multiple_product[index].qty.message}
                                                                                  </span>
                                               )}
                                             </FormGroup>
                                           </Col>
                                           <Col md={2}>
                                             <FormGroup>
                                               <Label for={`price_${index}`}>
                                                 Amount<span className="text-danger">*</span>
                                                </Label>
                                               <input
                                                 {...register(`multiple_product[${index}].price`, {
                                                   required: "Enter Value",
                                                   min: { value: 1, message: "Value must be >= 1" },
                                                   pattern: {
                                                     value: /^[+-]?\d+(\.\d+)?$/,
                                                     message: "Please Enter Valid Value",
                                                   },
                                                 })}
                                                 className="form-control-sm form-control"
                                                 placeholder="Enter Price"
                                                 id={`price_${index}`}
                                                 value={product.price}
                                                 onChange={(e) =>
                                                   handleInputChange(index, "price", e.target.value)
                                                 }
                                               />
                                               {errors.multiple_product?.[index]?.price && (
                                                 <span className="text-danger d-block error">
                                                   {errors.multiple_product[index].price.message}
                                                 </span>
                                               )}
                                             </FormGroup>
                                           </Col>
                                         </Row>
                                       ))}
                                       {(totalPrice >= 50000 || codValue >= 50000) && (
                                         <Row>
                                           <Col md={2}>
                                             <FormGroup>
                                               <Label for="product_Eway_no">
                                                 Enter Eway Bill No.<span className="text-danger">*</span>
                                               </Label>
                                               <input
                                                 {...register("product_Eway_no", { required: "Eway Bill No. is required" })}
                                                 type="text"
                                                 className="form-control-sm form-control"
                                                 placeholder="Enter Eway Bill No."
                                                 id="eway-bill"
                                                 value={ewayNumber}
                                                 onChange={(e) => setEwayNumber(e.target.value)}
                                               />
                                               {errors.product_Eway_no && (
                                                 <span className="text-danger d-block error">{errors.product_Eway_no.message}</span>
                                                                                )}
                                             </FormGroup>
                                           </Col>
                                         </Row>
                                       )}
                                        {/* <Col md={8}>
                                            <FormGroup>
                                                <Label for="product_name">Product Name<span className="text-danger">*</span></Label>
                                                <input {...register("product_name", { required: 'Please Enter Product Name' })} className="form-control-sm form-control" placeholder="Enter Product Name" id="product_name" onChange={(e) => inputChange(e)} />
                                                {errors.product_name && <span className="text-danger d-block error">{errors.product_name.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="quantity">Quantity<span className="text-danger">*</span></Label>
                                                <input {...register("product_qty", {
                                                    required: 'Please Enter Quantity',
                                                    min: { value: 1, message: "Please enter a value greater than or equal to 1." },
                                                    pattern: { value: /^[+-]?\d+(\d+)?$/, message: 'Please Enter Valid Quantity' }
                                                })} className="form-control-sm form-control" placeholder="Enter Product QTY" id="quantity" onChange={(e) => { inputChange(e); setQuantity(e.target.value); }} />
                                                {errors.product_qty && <span className="text-danger d-block error">{errors.product_qty.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        {/* {order_ndrs} */}
                                        {/* <Col md={2}>
                                            <FormGroup>
                                                <Label for="product_unit_price">Unit Value<span className="text-danger">*</span></Label>
                                                <input {...register("product_unit_price", {
                                                    required: 'Please Enter Unit Value',
                                                    min: { value: 1, message: "Please enter a value greater than or equal to 1." },
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Unit Value' }
                                                })} className="form-control-sm form-control" placeholder="" id="product_unit_price" onChange={(e) => { inputChange(e); setUnitprice(e.target.value); }} />
                                                {errors.product_unit_price && <span className="text-danger d-block error">{errors.product_unit_price.message}</span>}
                                            </FormGroup>
                                        </Col> */}
                                        
                                        {/* {unitprice>=50000 && <>
                                            <Col md={2}>
                                            <FormGroup>
                                                <Label for="product_Eway_no">Enter Eway Bill No.<span className="text-danger">*</span></Label>
                                                <input {...register("product_Eway_no")} type='text' className="form-control-sm form-control" placeholder="Enter Eway Bill No." id="eway-bill" onChange={(e) => { inputChange(e); setEwayNumber(e.target.value); }} />
                                            </FormGroup>
                                        </Col>
                                        </>} */}
                                        <Row className="justify-content-end">
                                          <Col><Label style={{fontSize:"12px",font:"bold", fontStyle:"italic", color:"burlywood"}}>*Add max 5 product</Label></Col>  
                                < Col sm={12} className="btn-container text-right">
                                    <Button className="btn ctm-btn btn-sm" style={{marginRight:"5px"}} color="primary" onClick={addProduct} >+</Button>
                                    {multiple_product.length > 1 && <Button className="btn ctm-btn btn-sm" color="primary" onClick={removeProduct}  >-</Button> }
                                </Col>
                            </Row>

                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="weight_in_kgs">Package Weight In KGS.<span className="text-danger">*</span> <small>eg.0.5</small></Label>
                                                <input {...register("weight_in_kgs", {
                                                    required: 'Please Enter Weight',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Weight' }
                                                })} className="form-control-sm form-control" id="weight_in_kgs" onChange={(e) => inputChange(e)} />
                                                {errors.weight_in_kgs && <span className="text-danger d-block error">{errors.weight_in_kgs.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="length_in_cms">Dimension (Length In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("length_in_cms", {
                                                    required: 'Please Enter Length',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Length' }
                                                })} className="form-control-sm form-control" id="length_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.length_in_cms && <span className="text-danger d-block error">{errors.length_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="breadth_in_cms">Dimension (Breadth In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("breadth_in_cms", {
                                                    required: 'Please Enter Breadth ',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Breadth ' }
                                                })} className="form-control-sm form-control" id="breadth_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.breadth_in_cms && <span className="text-danger d-block error">{errors.breadth_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="height_in_cms">Dimension (Height In CMS)<span className="text-danger">*</span></Label>
                                                <input {...register("height_in_cms", {
                                                    required: 'Please Enter Height',
                                                    pattern: { value: /^[+-]?\d+(\.\d+)?$/, message: 'Please Enter Valid Height ' }
                                                })} className="form-control-sm form-control" id="height_in_cms" onChange={(e) => inputChange(e)} />
                                                {errors.height_in_cms && <span className="text-danger d-block error">{errors.height_in_cms.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="order_no">Order Number</Label>
                                                <input {...register("order_no", { required: 'Please Enter Order Number' })} className="form-control-sm form-control" defaultValue={randomNumberByDate()} id="order_no" onChange={(e) => inputChange(e)} />
                                                {errors.order_no && <span className="text-danger d-block error">{errors.order_no.message}</span>}
                                            </FormGroup>
                                        </Col>
                                        {totalPrice !== '' && totalPrice > 0 &&
                                            <Col sm={12} className="d-flex">
                                                <FormGroup className="font-weight-500 ml-auto">
                                                    <span>Invoice Value :</span>
                                                    <span>{totalPrice}</span>
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                            <Row className="justify-content-end">
                                <Col sm={12} className="btn-container text-right">
                                    <Card><Button className="btn ctm-btn btn-sm" color="primary" onClick={handleSubmit(onSubmitPrice, onErrorUpdate)}>Get Courier</Button></Card>
                                </Col>
                            </Row>
                        </Form>
                        <Form>
                            {/* {checkData(courierwithPrice) && */}
                            {/* <Card className="custom-shadow border-0" name="scroll-to-element">
                <CardBody>
                    <Table responsive className="text-center">
                        <thead>
                            <tr>
                                <th>Choose Courier</th>
                                <th>Courier</th>
                                <th>Charged Weight In KGS.</th>
                                <th>Price</th>
                                <th>TAT</th>
                            </tr>
                        </thead>
                        <tbody> 
                            {checkData(courierwithPrice) ? courierwithPrice.map((item, index)=>(                        
                            <tr>
                                <td><input type="radio" disabled={item.price.total <= 0} {...register("courier_id")} value={item.courier_id} onClick={() => setZoneAndPrice({"zone_id" : item.zone_category_id, "total_price" : item.price.total, "all_prices":item.price, "courier_id":item.courier_id})} /><br />
                                </td>
                                <td>{item.courier_name}</td>
                                <td>{item.charged_weight_in_kgs || 'NA'}</td>
                                <td>{item.price.total || 'NA'}</td>
                                <td>{item.tat_in_days || 'NA'}</td>
                            </tr>
                            ))
                            :loader ? <tr> <td colSpan="7"><LoaderDefault /></td> </tr> :
                            <tr> <td colSpan="6"><NodataFound /></td> </tr> }
                        </tbody>
                    </Table>
                    {checkData(courierwithPrice)&&   
                    <Row>
                        <Col sm={12} className="text-right">
                            <Button className="btn btn-sm" color="primary" onClick={handleSubmit(onSubmitOrder, onErrorUpdate)}>Place Order</Button>
                        </Col>
                    </Row> }
                </CardBody>
              </Card> */}
                            {/* } */}

                            {checkData(courierwithPrice) &&
                              
                            <div className="courier-list" name="scroll-to-element">
                            <Table className='text-left custom-table channel-table table courier-table'>
  <thead>
    <tr>
      <th>Select</th>
      <th>Courier Name</th>
      <th>Charged Weight</th>
      <th>TAT</th>
      <th>Price</th>
    </tr>
  </thead>
  <tbody>
    {checkData(courierwithPrice) && courierwithPrice.map((item, index) => (
      <tr key={item.courier_id}>
        <td className='pl-4'>
          <input
            type="radio"
            disabled={(checkData(item.price) && checkUndeNullBlank(item.price.total) && item.price.total >= 0 ? false : true)}
            {...register("courier_id")}
            value={item.courier_id}
            id={item.courier_id}
            onClick={() => setZoneAndPrice({ "zone_id": item.zone_category_id, "total_price": checkData(item.price) && checkUndeNullBlank(item.price.total) && item.price.total, "all_prices": item.price, "courier_id": item.courier_id })}
          />
        </td>
        <td className="text-center d-flex align-items-center">
                <img
                  src={process.env.REACT_APP_IMAGE_COURIER + item?.courier_img}
                  alt="courier img"
                  className="courier_img img-fluid mr-3"
                  width="60"
                />
                <h6>{item.courier_name}</h6>
              </td>
              <td className="text-left">{item?.charged_weight_in_kgs || "NA"} Kg</td>
              <td className="text-left">{item?.tat_in_days || "NA"} Days</td>
              <td>
                <b>
                ₹
                {checkData(item?.price) &&
                checkUndeNullBlank(item?.price?.total)
                  ? item?.price?.total?.toFixed(2)
                  : "NA"}
                  </b>
              </td>
      </tr>
    ))}
  </tbody>
</Table>


                                <Row>
                                    {loader ? <Col md={12} className='d-flex justify-content-center'><LoaderDefault /></Col> : noData &&
                                        <Col md={12} className='nodataImage py-3 text-center'>
                                            <img src="images/noCourier.png" className="img-fluid h-auto" alt="Courier No Found" />
                                            <h6 className="text-muted pt-2">There is no courier serviceable for this Pincode / Service.</h6>
                                        </Col>
                                    }
                                </Row>
                                {checkData(courierwithPrice) &&
                                    <>
                                        <Row>
                                            <Col sm={12} className="text-right">
                                                <Button className="btn ctm-btn btn-sm" color="primary" onClick={handleSubmit(onSubmitOrder, onErrorUpdate)}>Place Order</Button>
                                            </Col>
                                        </Row>
                                        <Badge color='dark' pill className='w-100 my-2 text-right'>All Price are inclusive tax</Badge>
                                    </>
                                }
                            </div>
}
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default CreateOrder;