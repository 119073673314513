import React, { useState, useEffect } from 'react';
import { Row, Col, Button, CardTitle, FormGroup, Form, Label, Spinner, Table } from 'reactstrap';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Swal from 'sweetalert2'
import { errorHandling } from '../../utlis';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';


const MergeAccounts = (props) => {

    const [loader, setLoader] = useState(false);
    const [linkedAccount, setLinkedAccount] = useState({});

    const { register: mergeAccount, handleSubmit: mergeAccountSubmit, formState: { errors: errorMergeAccount }, reset: mergeAccountReset } = useForm();

    useEffect(() => {
        linkedAccountL();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const linkedAccountL = () => {
        setLoader(true);
        // let data = {}
        let data = { "login_merchant_id": props.merchantId }
        if (props.user[0].user_type === "Super") {
            data.admin_id = props.user[0].id
        } else {
            data.merchant_id = props.user[0].id
        }
        axios({
            method: "post",
            url: process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_LIST,
            data: data,
            headers: { "token": window.btoa(baseString) }
        }).then((res) => {
            setLoader(false)
            if (res.data.error === false) {
                setLinkedAccount(res.data.data);
            } else {
                Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
            }
        }).catch((err) => {
            setLoader(false)
            errorHandling(err, "reload");
        })
    }

    const removeMergeAccount = (id) => {
        let data = { "unlink_merchant_id": id ,"login_merchant_id": props.merchantId }
        data.admin_id = props.user[0].id
        setLoader(true);
            axios({
                method: "post",
                url: process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_REMOVE,
                data: data,
                headers: { "token": window.btoa(baseString) }
            }).then((res) => {
                setLoader(false);
                if (res.data.error === false) {
                    linkedAccountL();
                    Swal.fire({
                        title: 'Success',
                        text: res.data.msg,
                        icon: 'success',
                    })
                }
            })
            .catch((err) => {
                setLoader(false);
                errorHandling(err, "");
            });
    };

    const onMergeAccount = (data) => {
        data.admin_id = props.user[0].id
        data.login_merchant_id = props.merchantId
        setLoader(true);
        axios.post(process.env.REACT_APP_LINK_MERCHANT_ACCOUNT_ADD, data).then((res) => {
            setLoader(false);
            if (res.data.error === false) {
                mergeAccountReset(); linkedAccountL();
                Swal.fire({ title: 'Success', text: res.data.msg, icon: 'success', })
            } else {
                Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' })
            }
        }).catch((err) => {
            setLoader(false);
            errorHandling(err, "");
        });
    };


    return (
        <>
            {loader && <div className="formLoader"><Spinner /></div>}
            <Form onSubmit={mergeAccountSubmit(onMergeAccount)} >
                <div className="sub-page-header" >
                    <CardTitle tag="h4" className="sub-page-title"> Merge Account </CardTitle>
                </div>
                <Row className="align-items-end">
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="linkedId">Enter Merchant Id<span className="text-danger">*</span></Label>
                            <input {...mergeAccount("link_merchant_id", { required: 'Please Enter Merchant Id' })} className="form-control-sm form-control" placeholder="Enter Merchant Id" id="linkedId" />
                            {errorMergeAccount.link_merchant_id && <span className="text-danger d-block error position-absolute">{errorMergeAccount.link_merchant_id.message}</span>}
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Button className="ctm-btn btn-sm" color="primary" > Save changes </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
            <Table responsive className="text-center custom-table mb-0 mt-2">
                <thead>
                    <tr>
                        <th>S No.</th>
                        <th>Email</th>
                        <th>Company</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {checkData(linkedAccount) ? linkedAccount.map((item, index) => {
                        let email = checkUndeNullBlank(item.email) ? item.email : 'NA';
                        let comapnyName = checkUndeNullBlank(item.company_name) ? item.company_name : 'NA';
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td className="text-nowrap">{email}</td>
                                <td className="text-nowrap">{comapnyName}</td>
                                <td className="text-nowrap"><Button className="btn ctm-btn btn-sm text-nowrap" color="info" outline onClick={() => { removeMergeAccount(item.id) }}>Remove</Button></td>
                            </tr>)
                    }) : ''
                    }
                </tbody>
            </Table>
        </>
    );
}

export default MergeAccounts;
