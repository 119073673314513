import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, UncontrolledTooltip, Spinner } from 'reactstrap';
import { MultiSelect, } from "react-multi-select-component";
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'rsuite';
import TrackOrder from "../Orders/TrackOrder";
import axios from 'axios';
import Swal from 'sweetalert2';
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { useForm } from 'react-hook-form';
import { firstDayOftheMonth, copyToClipBoard, errorHandling, remExtraSpace, changeIntoDateTime } from '../../utlis';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { baseString, download_delay } from '../../config';
import moment from 'moment';

const Rto = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [allCourier, setAllCourier] = useState([]);
  const [allRtoShipment, setAllRtoShipment] = useState({});
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), });
  const [filterDate, setFilterDate] = useState([firstDayOftheMonth(), new Date()]);
  const [searchValue, setSearchValue] = useState(' ');
  const [orderTracking, setOrderTracking] = useState({});
  const [lastOrderStatus, setlastOrderStatus] = useState();
  const [sideAnim, setSideAnim] = useState(false);
  const [statusRecheckOrderId, setStatusRecheckOrderId] = useState([]);
  const [tempClass, setTempClass] = useState(null);

  const history = useHistory();

  const dropFilterCount = 20

  const onDateChange = (e) => {
    setFilterDate(e.text);
  };
  let dateRangeRef = dateRange => dateRangeRef = dateRange;

  const closeTracker = (data) => {
    setSideAnim(!sideAnim)
    setOrderTracking({});
    setTempClass(null);
  }

  const allRtoShipmentListing = () => {
    setFilterInput({ "end_date": moment().format('YYYY-MM-DD'), "start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), })
    setCurrentCount(1)
    setPage(1);
    setFilterDate([firstDayOftheMonth(), new Date()]);
    setAllCourier([]); setMerchants([]); setSearchValue(' ');
    setStatusRecheckOrderId([]); setOrderTracking({}); setSideAnim(false);
    reset();
    let data = { "order_status_id": [13], "event_start_date": moment(firstDayOftheMonth()).format('YYYY-MM-DD'), "event_end_date": moment().format('YYYY-MM-DD') }
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }

    setLoader(true);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_SHIPMENTS}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false)
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          setAllRtoShipment(res.data);
        }
      }
    }).catch((err) => {
      setLoader(false)
      errorHandling(err, "reload");
    });
  }
  const exportRto = () => {
    if (checkData(filterInput)) {
      let data = filterInput
      if (props.user[0].user_type === "Super") {
        data.admin_id = 1
      } else {
        data.merchant_id = props.user[0].id
      }
      data.order_status_id = [13]
      data.is_export = 1
      setLoader(true);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_ALL_SHIPMENTS}`,
        data: data,
        headers: { "token": window.btoa(baseString) }
      }).then((res) => {
        if (res.data !== undefined && res.data.fatal !== true && res.data !== true) {
          if (parseInt(res.data.errno) > 0) {
            setLoader(false)
            if (res.data.sqlMessage !== undefined) {
              Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
            }
          } else {
            if (res.data.is_export === 1) {
              setTimeout(function () {
                setLoader(false);
                history.push("/my-downloads");
              }, download_delay);
            }
          }
        }
      }).catch((err) => {
        setLoader(false)
        errorHandling(err, "");
      });
    } else {
      Swal.fire({ icon: 'error', title: 'Error', text: 'Please Choose Filter' })
    }
  }
  useEffect(() => {
    allRtoShipmentListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  const allCourierMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Couriers"
  }
  var opt_allCourier = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.couriers)) {
    Object.keys(props.state.shipmentMerchantCourier.couriers).map(function (key) {
      let tempObj = { label: props.state.shipmentMerchantCourier.couriers[key].courierName, value: props.state.shipmentMerchantCourier.couriers[key].courier_id }
      return opt_allCourier.push(tempObj)
    });
  }
  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  function nextPageData() {
    if (checkData(allRtoShipment.data) && checkUndeNullBlank(allRtoShipment.totalPage)) {
      // &&checkUndeNullBlank(allRtoShipment.totalPage).length>page.length
      if (allRtoShipment.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(allRtoShipment.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(allRtoShipment.data)
      if (currentCount > Object.entries(allRtoShipment.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }
  // filter Bulk Order
  const { register: filter, handleSubmit: filterRtoSubmit, watch: filterWatch, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onFilterRto = (data, pagePerpage) => {
    pagePerpage.page = 1
    if ((checkUndeNullBlank(data.search_field) && checkUndeNullBlank(data.search_value)) || checkData(filterDate) || checkData(merchants) || checkUndeNullBlank(data.delivery_type_id) || checkData(allCourier) || checkUndeNullBlank(data.attemped_count)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }

  };
  const onCommonFilter = (data, pagePerpage) => {
    setLoader(true)
    let byData = {}
    let courierId = [];
    let merchantsId = [];

    if (checkData(allCourier)) {
      allCourier.map((item) => (
        courierId.push(item.value)
      ));
    }

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }

    byData = { "order_status_id": [13], "search_field": checkUndeNullBlank(data.search_field) ? data.search_field : '', "search_value": checkUndeNullBlank(data.search_value) ? remExtraSpace(data.search_value) : '', "delivery_type_id": checkUndeNullBlank(data.delivery_type_id) ? data.delivery_type_id : '', "courier_id": checkUndeNullBlank(courierId) ? courierId : '', "merchant_ids": checkUndeNullBlank(merchantsId) ? merchantsId : '', "attemped_count": checkUndeNullBlank(data.attemped_count) ? data.attemped_count : '', "event_start_date": checkData(filterDate) ? moment(filterDate[0]).format('YYYY-MM-DD') : '', "event_end_date": checkData(filterDate) ? moment(filterDate[1]).format('YYYY-MM-DD') : '' }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = 1
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ALL_SHIPMENTS}?page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setAllRtoShipment(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };

  const orderTrack = (orderId, lastStatus) => {
    setlastOrderStatus(lastStatus)
    setStatusRecheckOrderId(orderId)
    let data = { "order_id": orderId }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_TRACK_ORDER}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setOrderTracking(res.data.data)
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      Swal.fire({ icon: 'error', text: err, title: 'Error' });
      setLoader(false);
    });
  }

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  return (
    <div className="app-content content overflow-hidden">
      {/* {loader && <div className="formLoader"><Spinner /></div>} */}
      {checkData(orderTracking) && <div className="overlay"></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 rtoShipment shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage RTO Delivered Shipments</CardTitle>
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  {checkData(allRtoShipment) && <div className="btn-text btn-light" onClick={exportRto}><span className="icon mr-2"><FontAwesomeIcon icon={faArrowDown} /></span><span>Download RTO Shipments</span>
                  </div>}
                </div>
              </CardHeader>
              <Form onSubmit={filterRtoSubmit(onFilterRto)} className="cardFilter">
                <Row className="mx-0 justify-content-start mb-3 mb-sm-0">
                  <Col sm={6} md={5} lg={4} xl={3}>
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="position-relative p-0">
                            <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                            <select {...filter("search_field")} className="form-control form-control-sm" onChange={(e) => { if (e.target.selectedOptions[0].text !== "Search By") { setSearchValue("Enter " + e.target.selectedOptions[0].text) } else { setSearchValue('') } setFilterDate([]) }}>
                              <option value="">Search By</option>
                              <option value="awb_number">AWB Number</option>
                              <option value="order_no">Order Number</option>
                              <option value="system_order_no">{process.env.REACT_APP_NAME} Order No.</option>
                            </select>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input {...filter("search_value")} type="text" className="form-control-sm form-control" placeholder={searchValue} onChange={() => { setFilterDate([]) }} />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={3}>
                    <FormGroup>
                      <DateRangePicker value={filterDate} onChange={date => setFilterDate(date)} format="dd-MM-yyyy" showOneCalendar={props.oneCalendar} placement="bottomEnd" ref={dateRangeRef} change={onDateChange} placeholder="Choose RTO Date" />
                    </FormGroup>
                  </Col>

                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={4} lg={3} xl={2}>
                      <FormGroup>
                        <div className="position-relative">
                          <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                          <MultiSelect
                            options={opt_merchants}
                            value={merchants}
                            onChange={setMerchants}
                            labelledBy="Select Merchant"
                            overrideStrings={merchantsMultiSelectSettings}
                            className={checkData(merchants) && "active"}
                          />
                        </div>
                      </FormGroup>
                    </Col>}

                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("attemped_count")} className={`form-control-sm form-control ${checkData(filterWatch("attemped_count")) ? 'valid' : ''}`}>
                        <option value="">Select Attempled Count</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                      <select {...filter("delivery_type_id")} className={`form-control-sm form-control ${checkData(filterWatch("delivery_type_id")) ? 'valid' : ''}`}>
                        <option value="">Select Payment Mode</option>
                        <option value="1">COD</option>
                        <option value="3">Prepaid</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <MultiSelect
                        options={opt_allCourier}
                        value={allCourier}
                        onChange={setAllCourier}
                        labelledBy="All Couriers"
                        overrideStrings={allCourierMultiSelectSettings}
                        className={checkData(allCourier) && "active"}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" outline onClick={allRtoShipmentListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(allRtoShipment) && checkData(allRtoShipment.data) && checkUndeNullBlank(allRtoShipment.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(allRtoShipment.data).length)} of {allRtoShipment.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th className="text-nowrap">Mobile</th>
                      <th className="text-nowrap">Courier</th>
                      <th className="text-nowrap">AWB Number</th>
                      <th className="text-nowrap">Order Number</th>
                      <th className="text-nowrap">Cod Value</th>
                      <th className="text-nowrap">Customer Name</th>
                      <th className="text-nowrap">Last Updated</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(allRtoShipment.data) ? allRtoShipment.data.map((item, index) => (
                      <tr key={index} className={`${tempClass === item.id && "showTop"}`}>
                        <td>{item.customer_mobile}</td>
                        <td>{item.courierName}</td>
                        <td>
                          <div className="text-nowrap"><span className="text-primary cursor-pointer" onClick={() => { setSideAnim(true); orderTrack(item.id, item.order_status_id); setStatusRecheckOrderId([item.id]); setTempClass(item.id) }}>{item.awb_no ? item.awb_no : "NA"}</span>
                            <span onClick={() => copied(item.awb_no ? item.awb_no : "NA")} className="ml-1 copy-btn" id={`copy${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copy${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </div>
                        </td>
                        <td><div className="text-nowrap"><Link target="_blank" to={`/order-detail/${item.id}`}>{item.order_no}</Link>
                          <span onClick={() => copied(item.order_no)} className="ml-1 copy-btn" id={`copyOdr${item.id}`}>
                            <Copy />
                            <UncontrolledTooltip placement="bottom" target={`copyOdr${item.id}`}>
                              Copy
                            </UncontrolledTooltip>
                          </span>
                        </div></td>
                        <td>{item.product_cod_value}</td>
                        <td className="text-nowrap">{item.customer_name}</td>
                        <td className="text-nowrap">{changeIntoDateTime(item.updated_at)}</td>
                        {/* <td className="text-nowrap">
                        <UncontrolledDropdown className="d-inline-block">
                          <DropdownToggle className="btn btn-sm" outline color="secondary" >
                            Request for POD<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link href="#" className="dropdown-item">Shipment Not Received</Link>
                            <Link href="#" className="dropdown-item">Reasion 2</Link>
                            <Link href="#" className="dropdown-item">Reasion 3</Link>
                            <Link href="#" className="dropdown-item">Reasion 4</Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td> */}
                      </tr>
                    )) :
                      loader ? <tr><td colSpan="7" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="7" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>


                {checkData(orderTracking) && <TrackOrder orderTracking={orderTracking} toggleTrack={sideAnim ? "show" : "hide"} closeTracker={(e) => { closeTracker(e) }} position="fixed" lastStatus={lastOrderStatus} statusRecheckOrderId={statusRecheckOrderId} user={props.user} />}

                {checkData(allRtoShipment) && checkData(allRtoShipment.data) && checkUndeNullBlank(allRtoShipment.TotalRows) ?
                  <>
                    {allRtoShipment.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}

export default connect(mapDispatchToProps)(Rto);