import React, { useState, useEffect } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import { Package, AlertTriangle, FileMinus ,CheckCircle, XCircle} from 'react-feather';
import { connect } from 'react-redux';
import classnames from 'classnames';
import BulkOrders from './BulkOrders';
import ProcessingOrder from './ProcessingOrder';
import FailedOrders from './FailedOrders';
import { useHistory } from 'react-router-dom';
import { errorHandling } from '../../utlis';
import Swal from 'sweetalert2'
import axios from 'axios';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { baseString } from '../../config';


const UploadOrders = (props) => {
  let orderTab = (new URLSearchParams(window.location.search)).get("o");
  let ShipmentStartDate = (new URLSearchParams(window.location.search)).get("start_date");
  let ShipmentEndDate = (new URLSearchParams(window.location.search)).get("end_date");
  
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('upload');
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab);
    history.push(`/orders?o=${tab}`)
  }

  let [bulkCount, setBulkCount] = useState(0);
  let [processCount, setProcessCount] = useState(0);
  let [failedCount, setFailedCount] = useState(0);
  const [successfulOrders,setSuccessfulOrders] = useState(0);



  useEffect(() => {
    orderCountListing();

    if (orderTab === null && ShipmentStartDate === null && ShipmentEndDate === null) {
      history.push(`/orders?o=${activeTab}`)
    }
    if (orderTab === 'upload') {
      setActiveTab('upload')
    } else if (orderTab === 'processing') {
      setActiveTab('processing')
    } else if ((orderTab === 'failed') || (ShipmentStartDate !== null && ShipmentEndDate !== null)) {
      setActiveTab('failed')
    } else {
      setActiveTab('upload')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTab, ShipmentStartDate, ShipmentEndDate])

  //orders count listing
  const orderCountListing = () => {
    let data = {}
    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ORDER_COUNT}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      if (res.data.error === false) {
        let myResponse = res.data.data
        if (checkData(myResponse)) {
          myResponse.map((item) => {
            let uploadCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 1)) ? parseInt(item.total) : 0
            let processingCount = (checkUndeNullBlank(item.order_status_id) && (parseInt(item.order_status_id) === 25)) ? item.total : 0
            let failedCount = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 3 ? item.total : 0
            let successCount = checkUndeNullBlank(item.order_status_id) && parseInt(item.order_status_id) === 2 ? item.success_orders : 0
            if (parseInt(uploadCount) > 0) {
              setBulkCount(uploadCount);
            }
            if (parseInt(processingCount) > 0) {
              setProcessCount(processingCount);
            }
            else{
              setProcessCount(0);
            } 
            if (parseInt(failedCount) > 0) {
              setFailedCount(failedCount);
            }
            if(parseInt(successCount) > 0){
              setSuccessfulOrders(successCount);
            }
                  
            return true
          });
        }
       
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }

      

    }).catch((err) => {
      errorHandling(err, "reload");
    })
  }

 

  useEffect(() => {
    let interval = null;
  
   
    if (processCount > 0) {
      interval = setInterval(() => {
        orderCountListing();
      }, 5000);
    }
  
  
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [processCount]);

     

 
  return (
    <div className="app-content content overflow-hidden">
      {
        processCount && processCount > 0 ? (
          <div
            style={{
              textAlign: "center",
              padding: "5px 10px",
              borderRadius: "5px",
              backgroundColor: "#e9f7fe",
                        display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              maxWidth: "300px",
              fontFamily: "'Roboto', sans-serif",
              fontSize: "14px",
              color: "#28a745",
              border: "1px solid #d4f1fb",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >          
            <div style={{ display: "flex", alignItems: "center" }}>
              <CheckCircle style={{ marginRight: "8px", fontSize: "18px" }} />
              <span style={{ fontWeight: "bolder" }}>Successful Orders :</span>
              <span style={{ fontWeight: "bold",marginLeft:"10px" }}>{successfulOrders || 0}</span>
            </div>          
               </div>
                  ) : ""
          } 
        

             <div className="p-0 my-3 orderMan content-wrapper border-0">
             <div className="">
               <Nav tabs className="text-center flex-nowrap">
                 <NavItem className="">
                   <NavLink className={`success-ctm position-relative ${classnames({ active: activeTab === 'upload' })}`} onClick={() => { toggleTab('upload'); }}>
                     <div className="d-flex flex-wrap justify-content-center align-items-center">
                       <span className="mr-2 icon"><Package /></span>
                       <span className="text-nowrap text">Upload</span>
                       <Badge Badge color="success" pill className="ml-sm-2 shadow-sm count">{bulkCount}</Badge>
                     </div>
                   </NavLink>
                 </NavItem>
                 <NavItem className="">
                   <NavLink className={`warning-ctm position-relative ${classnames({ active: activeTab === 'processing' })}`} onClick={() => { toggleTab('processing'); }}>
                     <div className="d-flex flex-wrap justify-content-center align-items-center">
                       <span className="mr-2 icon"><FileMinus /></span>
                       <span className="text-nowrap text">Processing</span>
                       <Badge color="warning" pill className="ml-sm-2 shadow-sm count">{processCount}</Badge>
                     </div>
                   </NavLink>
                 </NavItem>
                 <NavItem className="">
                   <NavLink className={`danger-ctm position-relative ${classnames({ active: activeTab === 'failed' })}`} onClick={() => { toggleTab('failed'); }}>
                     <div className="d-flex flex-wrap justify-content-center align-items-center">
                       <span className="mr-2 icon"><AlertTriangle /></span>
                       <span className="text-nowrap text">Failed</span>
                       <Badge color="danger" pill className="ml-sm-2 shadow-sm count">{failedCount}</Badge>
                     </div>
                   </NavLink>
                 </NavItem>
               </Nav>
             </div>
             <TabContent activeTab={activeTab} className="pb-0">
               <TabPane tabId="upload">
                 {activeTab === "upload" &&
                   <BulkOrders user={props.user} oneCalendar={props.oneCalendar} />
                 }
               </TabPane>
               <TabPane tabId="processing">
                 {activeTab === "processing" &&
                   <ProcessingOrder user={props.user} oneCalendar={props.oneCalendar} orderCountListing={orderCountListing} />
                 }
               </TabPane>
               <TabPane tabId="failed">
                 {activeTab === "failed" &&
                   <FailedOrders user={props.user} oneCalendar={props.oneCalendar} ShipmentStartDate={ShipmentStartDate} ShipmentEndDate={ShipmentEndDate} />
                 }
               </TabPane>
             </TabContent>
           </div>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(UploadOrders);
