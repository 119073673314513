import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, Spinner, UncontrolledTooltip,Modal,ModalHeader,ModalBody,Label,Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MultiSelect, } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";
import LoaderDefault from '../LoaderDefault';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { ChevronLeft, ChevronRight, Copy } from 'react-feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { errorHandling, remExtraSpace, changeIntoDate, copyToClipBoard } from '../../utlis';
import moment from 'moment';
import { baseString } from '../../config';

const MerchantInvoices = (props) => {
  
  const [merchantInvoiceList, setMerchantInvoiceList] = useState({});
  const [merchants, setMerchants] = useState([]);
  const [loadertable, setLoadertable] = useState(false);
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState({});
  const [loader, setLoader] = useState(false);
  const [defSelYear, setDefSelYear] = useState('');
  const [defSelMonth, setDefSelMonth] = useState('');
  const dropFilterCount = 20;

  let Year = moment().format('YYYY');
  let prevYear = moment().add(-1, 'year').format('YYYY');

  var opt_merchants = [];
  if (checkData(props.state) && checkData(props.state.shipmentMerchantCourier) && checkData(props.state.shipmentMerchantCourier.merchants)) {
    Object.keys(props.state.shipmentMerchantCourier.merchants).map(function (key) {
      let merchantName = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchantName) && props.state.shipmentMerchantCourier.merchants[key].merchantName
      let merchantId = checkUndeNullBlank(props.state.shipmentMerchantCourier.merchants[key].merchant_id) && props.state.shipmentMerchantCourier.merchants[key].merchant_id
      let tempObj = {}
      if (merchantName && merchantId) {
        tempObj = { label: merchantName, value: merchantId }
        opt_merchants.push(tempObj)
        // console.log('opt',opt_merchants);
      }
      return true
    });
  }
  const merchantsMultiSelectSettings = {
    "allItemsAreSelected": "All items are selected.",
    "clearSearch": "Clear Search",
    "noOptions": "No options",
    "search": "Search",
    "selectAll": "Select All",
    "selectSomeItems": "Select Merchant"
  }

  useEffect(() => {
    InvoiceListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { register: filter, handleSubmit: filterInvoiceListSubmit, reset } = useForm();
  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };

  const onFilterInvoiceList = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.invoice_no) || checkUndeNullBlank(data.bill_month) || checkData(data.bill_year) || checkData(merchants)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      // console.log(data,'data')
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };

  const InvoiceListing = () => {
    reset();
    setMerchants([]);

    if (props.user[0].user_type === "Super") {
      setDefSelYear(moment().format('YYYY'));
      setDefSelMonth(moment().add(-1, 'month').format('MM'));
    } else {
      setDefSelYear('');
      setDefSelMonth('');
    }

    let data = {};

    if (props.user[0].user_type === "Super") {
      data.bill_month = moment().add(-1, 'month').format('MM');
      data.bill_year = moment().format('YYYY');
    }

    if (props.user[0].user_type === "Super") {
      data.admin_id = props.user[0].id
    } else {
      data.merchant_id = props.user[0].id
    }
    setLoadertable(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INVOICE}?page=${page}&per_page=${selectperpage}`,
      data: data,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      setLoadertable(false)
      // console.log(res.data,'resssss');
      if (res.data.error === false) {
        setMerchantInvoiceList(res.data);
      } else {
        Swal.fire({ icon: 'error', text: res.data.msg, title: 'Error' });
      }
    }).catch((err) => {
      setLoadertable(false)
      errorHandling(err, "reload");
    });
  }

  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchantInvoiceList.data)
      if (currentCount > Object.entries(merchantInvoiceList.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  function nextPageData() {
    if (checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.totalPage)) {
      if (merchantInvoiceList.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchantInvoiceList.data).length)
        setPage(page + 1)
        setLoadertable(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoadertable(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }

  const onCommonFilter = (data, pagePerpage) => {
    let byData = {}
    let merchantsId = [];

    if (checkData(merchants)) {
      merchants.map((item) => (
        merchantsId.push(item.value)
      ));
    }
    setLoader(true)
    if (data === undefined) {
      data = filterInput
    }
    let mId = checkUndeNullBlank(merchantsId) ? merchantsId : '';

    byData = { "invoice_no": checkUndeNullBlank(data.invoice_no) ? remExtraSpace(data.invoice_no) : '', "bill_month": checkUndeNullBlank(data.bill_month) ? data.bill_month : '', "bill_year": checkUndeNullBlank(data.bill_year) ? data.bill_year : '', "merchant_ids": mId }
    setFilterInput(byData);
    if (props.user[0].user_type === "Super") {
      byData.admin_id = props.user[0].id
    } else {
      byData.merchant_id = props.user[0].id
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_MERCHANT_INVOICE}?page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage) && checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}`,
      data: byData,
      headers: { "token": window.btoa(baseString) }
    }).then((res) => {
      // console.log(res.data,'resnew')
      setLoader(false);
      if (res.data !== undefined && res.data.fatal !== true) {
        if (parseInt(res.data.errno) > 0) {
          if (res.data.sqlMessage !== undefined) {
            Swal.fire({ icon: 'error', text: res.data.sqlMessage, title: 'Error in SQL' });
          }
        } else {
          if (res.fatal !== true && res.error !== true) {
            setMerchantInvoiceList(res.data);
          }
          else {
            Swal.fire({ title: 'Error', text: res.msg, icon: 'error', })
          }
        }
      }
    }).catch((err) => {
      errorHandling(err, "");
      setLoader(false);
    });
  };

  // copy text
  const copied = (text) => {
    let decision = copyToClipBoard(text);
    decision.then(function (result) {
      if (result === true) {
        toast("Copied!", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    })
  }

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };


  const [formData, setFormData] = useState({
    start_date: '',
    end_date: '',
    due_date: '',
    admin_id:'1'
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'start_date') {
      const [year, month] = value.split('-');
      const startDate = `${year}-${month}-01`;
      const lastDay = new Date(year, month, 0).getDate();
      const endDate = `${year}-${month}-${lastDay}`;
      setFormData({
        ...formData,
        start_date: startDate,
        end_date: endDate,
      });

      // console.log('Start Date:', startDate);
      // console.log('End Date:', endDate);
    } else if (name === 'due_date') {
      setFormData({
        ...formData,
        due_date: value,
      });

      // console.log('Due Date:', value);
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData,'formm')
    axios({
      method: 'post',
      url: process.env.REACT_APP_MERCHANT_INVOICE_BY_RANGE,
      data: formData,
      headers: {
        "token": window.btoa(baseString),
        'Content-Type': 'application/json', 
      },
    }).then((res) => {
      // console.log(res, 'response');
      // Handle success, if needed
    })
    .catch((err) => {
      console.error(err);
      // Handle error, if needed
    })
    .finally(() => {
      closeModal();
    });
  };
  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 merchantInvoice shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex flex-wrap justify-content-between">
                <CardTitle className="page-title">Manage {props.user[0].user_type === "Super" && "Merchant"} Invoices</CardTitle>
              </CardHeader>
              <Form onSubmit={filterInvoiceListSubmit(onFilterInvoiceList)} className="cardFilter">
                <Row className="mx-0 mb-3 mb-sm-0 justify-content-start justify-content-md-center">
                  {props.user[0].user_type === "Super" &&
                    <Col sm={6} md={5} lg={4} xl={3}>
                      <FormGroup>
                        <MultiSelect
                          options={opt_merchants}
                          value={merchants}
                          onChange={(e) => { setMerchants(e); }}
                          labelledBy="Select Merchant"
                          overrideStrings={merchantsMultiSelectSettings}
                          className={checkData(merchants) && "active"}
                        />
                      </FormGroup>
                    </Col>}

                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <input  {...filter("invoice_no")} type="text" className="form-control-sm form-control" placeholder="Enter Invoice No." />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select value={defSelMonth} {...filter("bill_month")} className="form-control-sm form-control" onChange={(e) => { setDefSelMonth(e.target.value); }}>
                          <option value="">Select Month</option>
                          <option value="01">Jan</option>
                          <option value="02">Feb</option>
                          <option value="03">Mar</option>
                          <option value="04">Apr</option>
                          <option value="05">May</option>
                          <option value="06">Jun</option>
                          <option value="07">Jul</option>
                          <option value="08">Aug</option>
                          <option value="09">Sep</option>
                          <option value="10">Oct</option>
                          <option value="11">Nov</option>
                          <option value="12">Dec</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={3} xl={2}>
                    <FormGroup>
                      <div className="position-relative">
                        <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                        <select value={defSelYear} {...filter("bill_year")} className="form-control-sm form-control" onChange={(e) => { setDefSelYear(e.target.selectedOptions[0].text) }}>
                          <option value="">Select Year</option>
                          <option value={prevYear}>{prevYear}</option>
                          <option value={Year}>{Year}</option>
                        </select>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col sm={3} className="btn-container">
                    <Button className="btn ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="btn ctm-btn btn-sm mx-2" color="primary" onClick={InvoiceListing} outline>Reset</Button>
                    {props.user[0].user_type === "Super" && <Button type='button'className="btn ctm-btn btn-sm mx-2" color="primary" onClick={openModal} outline>Generate</Button>}
                     <Modal isOpen={isModalOpen} toggle={closeModal}>
                    <ModalHeader toggle={closeModal}>Generate Invoice</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleFormSubmit}>
            <FormGroup>
              <Label for="startDate">Start Date/End Date</Label>
              <Input
                type="month"
                id="startDate"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dueDate">Due Date</Label>
              <Input
                type="date"
                id="dueDate"
                name="due_date"
                value={formData.due_date}
                onChange={handleInputChange}
              />
            </FormGroup>
            <Button type="submit" color="primary">Submit</Button>
          </Form>
        </ModalBody>
      </Modal>
                  </Col>
                </Row>
              </Form>
              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchantInvoiceList.data).length)} of {merchantInvoiceList.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      {props.user[0].user_type === "Super" &&
                        <th className="text-nowrap">Merchant</th>}
                      <th className="text-nowrap">Invoice Number</th>
                      <th className="text-nowrap">Invoice Date</th>
                      <th className="text-nowrap">Due Date</th>
                      <th className="text-nowrap">GST</th>
                      <th className="text-nowrap">Invoice Amount</th>
                      <th className="text-nowrap">Payment Received/<br />Adjusted</th>
                      <th className="text-nowrap">Balance</th>
                      <th className="text-nowrap">Pay Status</th>
                      <th className="text-nowrap">View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(merchantInvoiceList.data) ? merchantInvoiceList.data.map((item, index) => {
                      let totalAmount = checkUndeNullBlank(item.net_amt) ? item.net_amt : 0
                      let totalAdjusted = checkUndeNullBlank(item.totalAdjust) ? item.totalAdjust : 0
                      let totalBalnce = (totalAmount - totalAdjusted).toFixed(2)
                      return (
                        <tr>
                          {props.user[0].user_type === "Super" &&
                            <td>{item.merchantName}</td>}
                          <td><Link target="_blank" to={`/invoice-detail/${item.id}`}>{item.invoice_no}</Link>
                            <span onClick={() => copied(item.invoice_no)} className="ml-1 copy-btn" id={`copyInvNo${item.id}`}>
                              <Copy />
                              <UncontrolledTooltip placement="bottom" target={`copyInvNo${item.id}`}>
                                Copy
                              </UncontrolledTooltip>
                            </span>
                          </td>
                          <td className='text-nowrap'>{changeIntoDate(item.invoice_date)}</td>
                          <td className='text-nowrap'>{changeIntoDate(item.due_date)}</td>
                          <td>{item.total_gst}</td>
                          <td>{totalAmount}</td>
                          <td>{totalAdjusted}</td>
                          <td>{totalBalnce}</td>
                          <td>
                            {item.pay_status === 0 && totalBalnce > 0 ?
                              <span className="text-danger">Unpaid</span> :
                              <span className="text-success">Paid</span>
                            }
                          </td>
                          <td>
                            <div className="text-nowrap">
                              <Link to={`/invoice-detail/${item.id}`}>Invoice</Link>
                            </div>
                          </td>
                        </tr>
                      )
                    }) :
                      loadertable ? <tr><td colSpan="10" className="text-center"><LoaderDefault /></td></tr>
                        : <tr><td colSpan="10" className="text-center"><NodataFound /></td></tr>}
                  </tbody>
                </Table>


                {checkData(merchantInvoiceList) && checkData(merchantInvoiceList.data) && checkUndeNullBlank(merchantInvoiceList.TotalRows) ?
                  <>
                    {merchantInvoiceList.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination aria-label="Page navigation example align-items-center">
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}

function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(MerchantInvoices);