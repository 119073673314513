import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Button, CardTitle, Spinner, FormGroup,Label, Form, CardHeader, Table, Pagination, PaginationItem, PaginationLink, DropdownToggle, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faChevronDown, faPlus, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'
import LoaderDefault from '../LoaderDefault';
import { changeIntoDate, remExtraSpace, errorHandling } from '../../utlis';
import Status from '../Status';
import NodataFound from '../NodataFound';
import { checkData, checkUndeNullBlank } from '../../ObjectExist';
import { connect, useDispatch } from 'react-redux'
import { AllMerchant } from '../../actions';
import { ChevronLeft, ChevronRight } from 'react-feather';
import AddMerchant from './AddMerchant';
import EditMerchant from './EditMerchant';
import { baseString } from '../../config';


const configData = require("../../config");
const Merchants = (props) => {
  const [merchant, setMerchant] = useState({})
  const [page, setPage] = useState(1)
  const [selectperpage, setSelectperpage] = useState(20);
  const [currentCount, setCurrentCount] = useState(1);
  const [filterInput, setFilterInput] = useState('');
  const [addmodal, setAddModal] = useState(false);
  const [editmodal, setEditmodal] = useState(false);
  const [editMerchant, setEditMerchant] = useState({});
  const [loader, setLoader] = useState(false);
  const [loadertable, setLoadertable] = useState(false);
  const dropFilterCount = 20
  const dispatch = useDispatch();
  const toggleAdd = () => setAddModal(!addmodal);

  const toggleEdit = (i) => {
    setEditMerchant({});
    setEditmodal(!editmodal);
    setLoader(true);
    fetch(`${process.env.REACT_APP_GET_MERCHANT}/view/${i}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        setLoader(false);
        setEditMerchant(result)
      });
    }).catch(function (response) {
      setLoader(false);
      errorHandling(response, "");
    });
  }

  function handleSelectChange(event) {
    setSelectperpage(event.target.value);
    setLoader(true)
    setPage(1)
    setCurrentCount(1)
    let pagePerpage = { "page": 1, "per_page": event.target.value };
    onpaginationFilter(filterInput, pagePerpage)
  }
  const merchantListing = () => {
    setLoadertable(true);
    setFilterInput('')
    setCurrentCount(1)
    resetFilter();
    setPage(1)
    let mypost = {}
    let myget = { "page": page, "per_page": selectperpage, "admin_id": props.user[0].id,"status":1 }
    dispatch(AllMerchant(mypost, myget)).then((response) => {
      setMerchant(response);
      setLoadertable(false)
    }).catch(function (response) {
      setLoadertable(false);
      errorHandling(response, "reload");
    });
  }


  useEffect(() => {
    if (checkData(props.state) && checkData(props.state.viewAllMerchant)) {
      setMerchant(props.state.viewAllMerchant);
    } else {
      merchantListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectperpage, page])
  
  function nextPageData() {
    if (checkData(merchant.data) && checkUndeNullBlank(merchant.totalPage)) {
      // &&checkUndeNullBlank(merchant.totalPage).length>page.length
      if (merchant.totalPage > page) {
        setCurrentCount(currentCount + Object.entries(merchant.data).length)
        setPage(page + 1)
        setLoader(true)
        let pagePerpage = { "page": page + 1, "per_page": selectperpage };
        onpaginationFilter(filterInput, pagePerpage);
      }
    }
  }
  function previousPageData() {
    if (page > 1) {
      setPage(page - 1)
      checkData(merchant.data)
      if (currentCount > Object.entries(merchant.data).length) {
        setCurrentCount(currentCount - selectperpage)
      } else {
        setCurrentCount(1)
      }
      let pagePerpage = { "page": page > 1 && page - 1, "per_page": selectperpage };
      onpaginationFilter(filterInput, pagePerpage);
    }
  }

  const { register, handleSubmit, reset: resetFilter } = useForm();

  const onpaginationFilter = (data, pagePerpage) => {
    onCommonFilter(data, pagePerpage)
  };
  const onSubmit = (data, pagePerpage) => {
    pagePerpage.page = 1
    if (checkUndeNullBlank(data.brand_name) || checkUndeNullBlank(data.email) || checkUndeNullBlank(data.status) || checkUndeNullBlank(data.merchant_id)) {
      setCurrentCount(1);
      setSelectperpage(selectperpage);
      setFilterInput(data);
      onCommonFilter(data, pagePerpage)
    } else {
      Swal.fire({ title: 'info', text: 'Please Choose Filter', icon: 'info', })
    }
  };
  const onCommonFilter = (data, pagePerpage) => {
    setFilterInput(data);
    setLoader(true)
    fetch(`${process.env.REACT_APP_GET_MERCHANT}?brand_name=${checkUndeNullBlank(data.brand_name) ? remExtraSpace(data.brand_name) : ''}&email=${checkUndeNullBlank(data.email) ? remExtraSpace(data.email) : ''}&merchant_id=${checkUndeNullBlank(data.merchant_id) ? data.merchant_id : ''}&status=${checkUndeNullBlank(data.status) ? data.status : 1}&serviceId=${checkUndeNullBlank(data.company_type_id)? data.company_type_id : 1}&page=${checkUndeNullBlank(pagePerpage.page) ? pagePerpage.page : page}&per_page=${checkUndeNullBlank(pagePerpage.per_page) ? pagePerpage.per_page : selectperpage}&admin_id=${checkUndeNullBlank(props.user[0].id) ? props.user[0].id : ''}`, {
      method: 'POST',
      headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        "token": window.btoa(baseString)
      },
    }).then((resp) => {
      resp.json().then((result) => {
        if (result.fatal !== true) {
          setLoader(false)
          setMerchant(result)
        }
      });
    }).catch((err) => {
      setLoader(false);
      errorHandling(err, "");
    });
  };
  // const openNewWindow = (url) => {
  //   var windowObjectReference;
  //   var windowFeatures = "popup";
  //   windowObjectReference = window.open(url, "login", windowFeatures);
  // };

  // console.log(props.permission,'per')

  // const isAddAllowed = props?.permission?.RegisteredMerchant && props?.permission?.RegisteredMerchant.includes('Add');
  // const isactiveAllowed = props?.permission?.RegisteredMerchant && props?.permission?.RegisteredMerchant.includes('Active');
  // const iseditAllowed = props?.permission?.RegisteredMerchant && props?.permission?.RegisteredMerchant.includes('Edit');
  // const isCourierdetailAllowed = props?.permission?.RegisteredMerchant && props?.permission?.RegisteredMerchant.includes('Merchantdetail');

  // const isAddAllowed=true;       
  // const isactiveAllowed=true;
  // const iseditAllowed=true;
  // const isCourierdetailAllowed =true;
// console.log(merchant.data,'wef')


  return (
    <div className="app-content content overflow-hidden">
      {loader && <div className="formLoader"><Spinner /></div>}
      <Row>
        <Col sm={12}>
          <Card className="p-0 my-3 manageMerchant shadow content-wrapper border-0">
            <Card className="border-0">
              <CardHeader className="page-header d-flex justify-content-between flex-wrap">
                <CardTitle className="page-title">Manage Merchants</CardTitle>
           
                <div className="card-header-right d-flex flex-wrap align-items-center ml-auto">
                  <div className="btn-text btn-light" onClick={toggleAdd}><span className="icon mr-2"><FontAwesomeIcon icon={faPlus} /></span><span>Add Merchant</span>
                  </div>
                </div>
              
                <AddMerchant addmodal={addmodal} toggleAdd={toggleAdd} setAddModal={setAddModal} setLoader={setLoader} user={props.user} merchantListing={merchantListing} />
                {checkData(editMerchant) &&
                  <EditMerchant user={props.user} editMerchant={editMerchant} editmodal={editmodal} setEditmodal={setEditmodal} setLoader={setLoader} merchantListing={merchantListing} filterInput={filterInput} page={{ "page": 1 }} onCommonFilter={onCommonFilter} />
                }
              </CardHeader>
              <Form onSubmit={handleSubmit(onSubmit)} className="cardFilter">
                <Row className="mx-0 justify-content-start justify-content-md-center">
                  <Col sm={6} md={4} lg={4} xl={4}>
                    <FormGroup>
                      <input {...register("brand_name")} className="form-control-sm form-control" placeholder="Brand Name" />
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={4} lg={4} xl={4}>
                    <FormGroup>
                      <input {...register("email")} type="email" className="form-control-sm form-control" placeholder="Enter Email" />
                    </FormGroup>
                  </Col>
                  <Col sm={4} md={3} lg={2} xl={2}>
                    <FormGroup>
                      <input {...register("merchant_id")} type="text" className="form-control-sm form-control" placeholder="Merchant Id" />
                    </FormGroup>
                  </Col>
             
                  <Col sm={3} md={3} lg={3} xl={3}>
                     <FormGroup>
                     <FormGroup check className="pl-0">
                     <Label style={{fontWeight:"bold", marginLeft:"10px"}} >Status</Label>
                      <div>
                        <span className="form-check2 mr-2">
                          <input {...register("status")} type="radio" value='1' id="active" defaultChecked />
                          <Label className="mx-2" for="active">Active</Label>
                        </span>
                        <span className="form-check2 mr-2">
                          <input {...register("status")} type="radio" value='0' id="inActive" />
                          <Label className="mx-2" for="inActive">InActive</Label>
                        </span>
                        </div>
                      </FormGroup>
                     </FormGroup>
                      
                    

                  </Col>
                  <Col sm={4} md={4} lg={4} xl={4}>
                  <FormGroup>
                  
                      <FormGroup check className="pl-0">
                        <Label style={{fontWeight:"bold" , marginLeft:"10px"}} >Service Type</Label>
                        <div>
                        <span className="form-check2 mr-2">
                          <input {...register("company_type_id")} type="radio" value='1' id="Logistify" defaultChecked />
                          <Label className="mx-2" for="Logistify">Logistify</Label>
                        </span>
                        <span className="form-check2 mr-2">
                          <input {...register("company_type_id")} type="radio" value='2' id="Markting" />
                          <Label className="mx-2" for="Markting">Marketing</Label>
                        </span>
                        </div>
                      </FormGroup>
                      
                    
                  </FormGroup>
                    
                  </Col>
                  <Col sm={3} className="btn-container ml-auto">
                    <Button className="ctm-btn btn-sm" color="primary">Search</Button>
                    <Button type="reset" className="ctm-btn btn-sm mx-2" color="primary" outline onClick={merchantListing}>Reset</Button>
                  </Col>
                </Row>
              </Form>

              <CardBody className="p-0">
                <div className="showing-count">
                  {checkData(merchant) && checkData(merchant?.data) && checkUndeNullBlank(merchant?.TotalRows) ?
                    <span class="small">Showing {currentCount} to {(currentCount - 1) + (Object.entries(merchant.data).length)} of {merchant.TotalRows}</span> : ''}
                </div>
                <Table responsive className="text-center custom-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th className="text-nowrap">company Name</th>
                      <th className="text-nowrap">Merchant Email</th>
                      <th className="text-nowrap">Registered Date</th>
                      <th className="text-nowrap">Trading Date</th>
                      <th className="text-nowrap">Account Type</th>
                      <th className="text-nowrap">Service Type</th>
                      <th>Limit/Balance</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkData(merchant.data) ? merchant.data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.company_name}</td>
                        <td>{item.email}</td>
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                        <td className="text-nowrap">{(item.created_at !== undefined && item.created_at !== null ? changeIntoDate(item.created_at) : 'NA')}</td>
                        <td>{configData.account_type[item.billing]}</td>
                        <td>{item.company_type_id === 1 ? "Logistify" : "Marketing" }</td>
                        <td>{item.wallet_balance}</td>
                        <td className="text-nowrap">
                          <div className="btn-group-ctm">
                            <span className="mr-2"><Status statusUrl={process.env.REACT_APP_GET_MERCHANT} listId={item.id} valStatus={item.status} reListing={merchantListing} data={{ "merchant_id": item.id, "status": item.status === 1 ? 0 : 1 }} /></span>
                            <span><Button className="ctm-btn btn-sm ml-2" color="info" outline onClick={() => toggleEdit(item.id)}>Edit</Button></span>
                            <span><UncontrolledDropdown className="d-inline-block">
                              <DropdownToggle className="ctm-btn btn-sm ml-2" outline color="secondary" >
                                More<span className="ml-1"><FontAwesomeIcon icon={faAngleDown} /></span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <Link to={`/merchants-details/${item.id}/2`} className="dropdown-item">Merchant Details</Link>
                                <Link to={`/merchants-details/${item.id}/4`} className="dropdown-item">Reset Password</Link>
                                <Link to={`/merchants-details/${item.id}/10`} className="dropdown-item">Merge Account</Link>
                                <Link to={`/merchants-pickuppoint/${item.id}`} className="dropdown-item">Pickup Points</Link>
                                <Link to={`/merchant-pricing/${item.id}?merchantName=${item.brand_name}`} className="dropdown-item">Merchant Courier</Link>
                                <Link to={`/merchants-brand/${item.id}`} className="dropdown-item">Merchant Brands</Link>
                                <Link to={`/merchants-passbook/${item.id}`} className="dropdown-item">Merchant Passbook</Link>
                                {/* <Link to={`/merchants-ledger/${item.id}`} className="dropdown-item">Merchant Ledger</Link> */}
                                <Link to={`/login?${item.id}`} className="dropdown-item">Merchant Login</Link>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            </span>
                          </div>
                        </td>
                      </tr>
                    )) : loadertable ? <tr><td colSpan="8"><LoaderDefault /></td></tr> :
                      <tr><td colSpan="8"><NodataFound /></td></tr>}
                  </tbody>
                </Table>
                {checkData(merchant) && checkData(merchant.data) && checkUndeNullBlank(merchant.TotalRows) ?
                  <>
                    {merchant.TotalRows > dropFilterCount ?
                      <Row className="m-0 table-footer">
                        <Col xs="6">
                          <Pagination>
                            <PaginationItem className="prev">
                              <PaginationLink previous to="#" onClick={previousPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronLeft /></PaginationLink>
                            </PaginationItem>
                            <PaginationItem className="next ml-3">
                              <PaginationLink next to="#" onClick={nextPageData} className="rounded-circle d-flex justify-content-center align-items-center"><ChevronRight /></PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </Col>
                        <Col xs="6">
                          <Form>
                            <FormGroup>
                              <span className="arrowdown"><FontAwesomeIcon icon={faChevronDown} /></span>
                              <select type="select" name="select" value={selectperpage} onChange={handleSelectChange} id="exampleSelect" className="form-control-sm form-control">
                                <option value={selectperpage} disabled className="border-bottom">{selectperpage}</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </FormGroup>
                          </Form>
                        </Col>
                      </Row> : ''}
                  </>
                  : ''}
              </CardBody>
            </Card>
          </Card>
        </Col>

      </Row>
    </div>
  );
}
function mapDispatchToProps(state) {
  return {
    state: state.DataReducer
  }
}
export default connect(mapDispatchToProps)(Merchants);